import React, { KeyboardEvent, MouseEvent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faCheck,
  faCommentDots,
  faCommentSlash,
  faFileLines,
  faMessage,
  faRobot,
  faUser,
  IconDefinition,
  faStopwatch,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import moment from 'moment';
import 'moment/locale/es';
import { ConversationStatus } from '../constants';

export default function ConversationListItem({ conversation }) {
  moment.locale('es');
  const params = useParams();
  const isActive = params?.id == conversation.id;
  const mostRecentMessage = conversation.messages[0];

  function handleOrderLinkClick(e: MouseEvent<HTMLSpanElement> | KeyboardEvent<HTMLSpanElement>) {
    // prevent clicking card's link when clicking order's link
    e.preventDefault();
    e.stopPropagation();
  
    window.open(`${window.location.origin}/orders/${conversation.order.id}`, '_blank');
  };
  
  function handleOrderLinkKeyDown(e: KeyboardEvent<HTMLSpanElement>) {
    if (e.key === 'Enter' || e.key === ' ') {
      handleOrderLinkClick(e);
    }
  };
  
  function renderCompanyName() {
    let companyName = conversation.client?.razonSocial;
    if (!companyName) {
      companyName = conversation?.contact?.Client?.razonSocial;
    }

    return companyName ?? null;
  };

  function renderSenderIcon() {
    let icon: IconDefinition = faUser;
    let size: SizeProp = '2xs';
    let title: string = 'Mensaje de cliente';

    // if there's no message, then no icon
    if (!mostRecentMessage) {
      return null;
    }

    if (mostRecentMessage.senderType === 'ordertob') {
      icon = faRobot;
      size = 'xs';
      title = 'Mensaje de OrderToB';
    }
    return <FontAwesomeIcon icon={icon} title={title} fixedWidth size={size} />;
  };

  function renderStatusIcon() {
    let icon: IconDefinition = faCheck;
    let title: string = 'Conversación finalizada';

    if (conversation.status === ConversationStatus.inProgress) {
      icon = faCommentDots;
      title = 'Conversación en proceso';
    } else {
      if (conversation.status === ConversationStatus.expired) {
        icon = faXmark;
        title = 'Conversación expirada';
        
      } else if (conversation.status != ConversationStatus.finishedOrder) {
        icon = faCommentSlash;
        title = 'Conversación cancelada';
      }
    }
    return <FontAwesomeIcon icon={icon} title={title} fixedWidth size="xs" />;
  };

  function renderChannelIcon() {
    let icon: IconDefinition;
    let title: string;

    switch (conversation.sourceChannel) {
      case 'email':
        icon = faEnvelope;
        title = 'Email';
        break;
      case 'telegram':
        icon = faTelegram;
        title = 'Telegram';
        break;
      case 'whatsapp':
        icon = faWhatsapp;
        title = 'Whatsapp';
        break;
      default:
        icon = faMessage;
        title = 'Mensajero';
    }
    return <FontAwesomeIcon icon={icon} title={title} fixedWidth size="xs" />;
  };

  function renderContactInfo() {
    const mobileNumberOrEmailAddress = (): string => {
      if (conversation.sourceChannel === 'whatsapp' || conversation.sourceChannel === 'telegram') {
        return conversation.contact?.phoneNumber;
      }
      return conversation.contact?.email;
    };

    if (conversation.contact?.firstName != null) {
      return (
        <span data-bs-toggle="tooltip" data-bs-html="true" title={mobileNumberOrEmailAddress()}>
          {`${conversation.contact?.firstName} ${conversation.contact?.lastName}`}
        </span>
      );
    }

    return <span>{conversation.externalCode}</span>;
  };

  function renderChatDuration() {
    const duration = moment.duration(
      moment(conversation.finishedAt ?? mostRecentMessage.createdAt).diff(
        moment(conversation.createdAt),
      ),
    );
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
  
    return <span>{`${hours}:${minutes.toString().padStart(2, '0')}`}</span>;
  }

  function renderLastMessageDate() {
    const messageDate = mostRecentMessage?.createdAt ?? conversation.createdAt;
    const dateToDisplay = moment(messageDate).format('DD/MM/YYYY HH:mm');

    if (moment(messageDate).isAfter(moment().subtract(24, 'hours'))) {
      return (
        <span data-bs-toggle="tooltip" data-bs-html="true" title={dateToDisplay}>
          {moment(messageDate).fromNow()}
        </span>
      );
    }
    // force the text to split in 2 lines
    return <span style={{ whiteSpace: 'pre-line' }}>{dateToDisplay.split(' ').join('\n')}</span>;
  };

  function renderLastMessage() {
    // if there's no message, then there's no message content to show
    if (!mostRecentMessage) {
      return <em>(No hay mensajes)</em>;
    }

    if (mostRecentMessage?.contentType === 'audio') {
      return 'MENSAJE DE AUDIO';
    } else if (mostRecentMessage.contentType === 'document') {
      return 'MENSAJE CON IMAGEN/ARCHIVO';
    } else {
      return mostRecentMessage.content;
    }
  };

  return (
    <Link
      to={`/conversations/${conversation.id}`}
      className={`list-group-item list-group-item-action py-2 lh-sm ${
        isActive ? 'conversation-list-custom-active' : ''
      }`}
      aria-current={isActive}>
      <span
        className="col-10 small text-uppercase"
        style={{ color: renderCompanyName() ? '#5555ff' : '#e35d6a' }}>
        {renderCompanyName() ?? 'NO HAY CLIENTE ASOCIADO'}
      </span>
      <div
        className="d-flex w-100 mb-1 align-items-center justify-content-between no-wrap"
        style={{ lineHeight: '.85rem' }}>
        <div className="d-flex fs-6">{renderContactInfo()}</div>
        <div className="d-flex flex-column text-center small text-muted">{renderLastMessageDate()}</div>
      </div>
      <div className="col-10 small text-muted d-flex align-items-center gap-1">
        {renderSenderIcon()}
        <span className="text-truncate">{renderLastMessage()}</span>
      </div>
      <div className="d-flex w-100 mt-3 align-items-center justify-content-end gap-2">
        {conversation.order?.id && (
          <>
            <FontAwesomeIcon icon={faFileLines} fixedWidth size="xs" />
            {/* We are putting this link as a span because the entire item 
            is a Link and per HTML validation standards there shouldn't be <a> inside <a> */}
            <span
              onClick={handleOrderLinkClick}
              onKeyDown={handleOrderLinkKeyDown}
              role="link"
              tabIndex={0}>
              {`Orden #${conversation.order?.codigo ?? conversation.order.id}`}
            </span>
          </>
        )}
        <FontAwesomeIcon icon={faStopwatch} fixedWidth size="xs" />
        {renderChatDuration()}
        {renderStatusIcon()}
        {renderChannelIcon()}
      </div>
    </Link>
  );
}
