import XLSX from 'xlsx';
import config from '../config';
import { DownloadFileType } from '../constants';

// XLSX librarie
export const generateWorkbookObject = (arrayData) => {
  const wb = XLSX.utils.book_new();
  wb.SheetNames.push('1');
  const ws_data = arrayData; //a row with 2 columns
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets['1'] = ws;
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  return wbout;
};

export const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

export const CSVToArrays = (csv) => {
  const rowsArray = csv.split('┴');
  const response = rowsArray.map((rowString, index) => {
    if (index === 0) {
      return rowString.split(',');
    }
    return rowString.split('|');
  });
  return response;
};

// ///
export const parseDataToExport = async (data, headersCol, exportExtraFields, customHeaders) => {
  let headers = headersCol.map((col) => col.dataField);

  let headerNames =
    customHeaders[0] === '' ? headersCol.map((col) => col.dataField) : customHeaders;
  if (exportExtraFields[0] !== '') {
    headers = headers.concat(exportExtraFields);
    headerNames = headerNames.concat(exportExtraFields);
  }
  const dataColumns = data.map((datO) =>
    headers.map((h) => {
      // nested data
      if (h.indexOf('.') > 0) {
        // only for level 1 nested (i.e. nested.length = 2)
        const nested = h.split('.');
        return datO[nested[0]] ? datO[nested[0]][nested[1]] : '';
      }
      return datO[h];
    }),
  );
  dataColumns.unshift(
    headerNames.map((h) => {
      if (h.indexOf('.') > 0) {
        return h.split('.')[0];
      }
      return h;
    }),
  );
  return dataColumns;
};

export const getNowDateLocale = () => {
  const dateNow = Date.now();
  const dateString = new Date(dateNow);
  return dateString.toLocaleDateString(config.date.locale);
};

export const getSimplifiedMimeType = (mimeType) => {
  return mimeType.split(';')[0];
};

export const getTypeFromMimeType = (mimeType): DownloadFileType => {
  const simplifiedMimeType = getSimplifiedMimeType(mimeType);
  const audioMimeTypes = [
    'audio/mpeg',
    'video/mp4',
    'audio/wav',
    'application/ogg',
    'audio/ogg',
    'audio/weba',
    'video/webm',
  ];
  if (simplifiedMimeType.startsWith('audio') || audioMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.audio;
  }

  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/webp',
    'image/gif',
    'image/avif',
    'image/tiff',
    'image/svg+xml',
    'image/bmp',
  ];
  if (simplifiedMimeType.startsWith('image') || imageMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.image;
  }

  const spreadsheetMimeTypes = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  if (spreadsheetMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.spreadsheet;
  }

  const pdfMimeTypes = ['application/pdf'];
  if (pdfMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.pdf;
  }

  const csvMimeTypes = ['text/csv'];
  if (csvMimeTypes.includes(simplifiedMimeType)) {
    return DownloadFileType.csv;
  }
  return DownloadFileType.document;
};
