import React from 'react';
import { AxiosResponse } from 'axios';
import { Row, Col, Card } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import {
  EntityEditForm,
  FormAddressInput,
  FormInputField,
  FormCheckField,
  FormSelectField,
} from '../../components';
import { checkDayData, WeekDaySelection } from './utils';
import APIClient from '../../services/APIClient';
import { WithRouterProps, withRouter } from '../withRouter';
import { Cliente, DeliveryPoint, Provincia } from '../../types/model';

function PuntoEntregaEdit({ navigate, params, toastManager }: WithRouterProps) {
  const [id] = React.useState(params.id);
  const [isAdding] = React.useState(typeof id === 'undefined');
  const [clientes, setClientes] = React.useState<Cliente[]>([]);
  const [entity, setEntity] = React.useState<DeliveryPoint>({} as DeliveryPoint);
  // const [expresos, setExpresos] = React.useState<any[]>([]);
  const [provincias, setProvincias] = React.useState<Provincia[]>([]);
  const [checkedDays, setCheckedDays] = React.useState<string[]>([]);
  const [daysSelection, setDaysSelection] = React.useState<WeekDaySelection[]>([]);

  async function handleFormLoad() {
    // get provincias
    const provinciasRes = await APIClient.get('/provincias');

    // get clientes
    const clientesRes = await APIClient.get('/clientes?sortField=razonSocial&sortDir=asc');

    // get expresos
    const expresosRes = await APIClient.get('/expresos');

    setClientes(clientesRes.data.data);
    // setExpresos(expresosRes.data.data);
    setProvincias(provinciasRes.data.data);
    setDaysSelection(checkDayData([]));
  }

  async function handleFormRetrieveEntity() {
    // get puntoEntrega
    const puntoEntregaRes = await APIClient.get(`/puntos-entrega/${id}`);
    let deliveryPointCheckedDays: string[] = [];
    if (puntoEntregaRes.data.data.deliveryDays) {
      try {
        deliveryPointCheckedDays = JSON.parse(puntoEntregaRes.data.data.deliveryDays);
      } catch (error) {
        console.warn('Error parsing delivery days');
      }
    }
    setEntity(puntoEntregaRes.data.data);
    setCheckedDays(deliveryPointCheckedDays);
    setDaysSelection(
      deliveryPointCheckedDays ? checkDayData(deliveryPointCheckedDays) : checkDayData([]),
    );

    return puntoEntregaRes.data.data;
  }

  /**
   * Save the item
   */
  async function handleFormSaveEntity(entityToSave) {
    let saveResponse: AxiosResponse;
    entityToSave.deliveryDays = JSON.stringify(checkedDays);
    if (isAdding) {
      saveResponse = await APIClient.post('/puntos-entrega', entityToSave);
    } else {
      if (entityToSave.diasEntrega || entityToSave.entregaHorario) {
        await APIClient.patch(`/clientes/${entity.clienteId}`, entityToSave);
      }
      saveResponse = await APIClient.patch(`/puntos-entrega/${id}`, entityToSave);
    }

    navigate && navigate('/puntos-entrega');
    toastManager.add(`Punto de Entrega ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  function handleDayChecked(event) {
    const { id, checked } = event.target;
    let newCheckedDays = checkedDays;

    if (checked) {
      newCheckedDays.push(id);
    } else {
      newCheckedDays = newCheckedDays.filter((element) => element !== id);
    }
    setCheckedDays(newCheckedDays);
  }

  return (
    <div>
      <h1 className="page-title">
        {isAdding ? 'Punto de Entrega nuevo' : `Punto de Entrega #${id}`}
      </h1>

      <EntityEditForm
        onLoadForm={handleFormLoad}
        onRetrieveEntity={handleFormRetrieveEntity}
        onSaveEntity={handleFormSaveEntity}
        addMode={isAdding}>
        <>
          <Row>
            <Col md={6}>
              <FormInputField
                id="descripcion"
                label="Descripción"
                as="input"
                defaultValue={entity.descripcion}
              />
            </Col>
          </Row>

          <FormAddressInput addMode={isAdding} entity={entity} provincias={provincias} />

          <Row>
            <Col md={6}>
              <FormInputField id="email" label="Email" as="input" defaultValue={entity.email} />
            </Col>
            <Col md={6}>
              <FormInputField
                id="telefono"
                label="Teléfono"
                as="input"
                defaultValue={entity.telefono}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormSelectField
                id="clienteId"
                label="Cliente"
                choices={clientes}
                choiceIdField="id"
                choiceLabelField="razonSocial"
                defaultValue={String(entity.clienteId)}
                placeholder="(Sin Cliente asociado)"
                disabled={true}
              />
            </Col>
            {/* <Col md={6}>
                <FormSelectField
                  id="expresoId"
                  label="Expreso"
                  type="text"
                  choices={expresos}
                  choiceIdField="id"
                  choiceLabelField="descripcion"
                  defaultValue={entity.expresoId}
                  placeholder="(Sin Expreso)"
                  disabled={true}
                />
              </Col> */}
          </Row>
          <Row>
            <Col md={6}>
              <Card>
                <Card.Header>Horarios de entrega</Card.Header>
                <Card.Body>
                  <Row>
                    {daysSelection.map((daySelection) => (
                      <Col key={daySelection.key} md={2}>
                        <FormCheckField
                          id={daySelection.key}
                          label={daySelection.label}
                          defaultChecked={daySelection.checked}
                          onChange={handleDayChecked}
                        />
                      </Col>
                    ))}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormInputField
                        id="deliveryTime"
                        label="Horario"
                        as="input"
                        defaultValue={entity.deliveryTime}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              <FormCheckField
                id="isEliminado"
                label="Deshabilitado"
                defaultChecked={entity.isEliminado}
              />
            </Col>
          </Row>
        </>
      </EntityEditForm>
    </div>
  );
}

export default withToastManager(withRouter(PuntoEntregaEdit));
