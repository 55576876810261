import moment from 'moment';

export const buildYearsOptions = () => {
  const years: { year: string }[] = [];
  const dateStart = moment('01-01-2000');
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    years.push({ year: dateStart.format('YYYY') });
    dateStart.add(1, 'year');
  }
  return years;
};

export const defineMainTableDataProvider = async (providers) => [
  ...providers
    .filter((provider) => !provider.eliminadoFlag)
    .map((provider) => {
      // initialize defaults.
      provider.solicitado = 0;
      provider.precioSolicitado = 0.0;
      provider.pendiente = 0;
      provider.precioPendiente = 0.0;
      provider.facturado = 0;
      provider.precioFacturado = 0.0;
      provider.detalles = [];
      provider.productCategory1 = '';
      provider.proveedor = '';
      return provider;
    }),
];

export const defineMainTableDataCustomer = async (customers) => [
  ...customers
    .filter((customer) => !customer.eliminadoFlag)
    .map((customer) => {
      // initialize defaults.
      customer.solicitado = 0;
      customer.precioSolicitado = 0.0;
      customer.pendiente = 0;
      customer.precioPendiente = 0.0;
      customer.facturado = 0;
      customer.precioFacturado = 0.0;
      customer.detalles = [];
      customer.productCategory1 = '';
      customer.proveedor = '';
      return customer;
    }),
];
