import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

const FormSwitch = (props) => {
  const { id, switchLabel, checked, onChange, label, labelField } = props;
  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Check type="switch" id={id} label={switchLabel} defaultChecked={checked} name={labelField} />
    </Form.Group>
  );
};

FormSwitch.propTypes = {
  labelField: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  switchLabel: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

FormSwitch.defaultProps = {
  checked: false,
  onChange: () => {},
  label: '',
  switchLabel: '',
};
export default FormSwitch;
