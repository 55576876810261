import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import APIClient from '../services/APIClient';
import ConversationMessageBubble from './ConversationMessageBubble';
import { Conversation } from '../types/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import './conversation.css';

interface ConversationContainerProps {
  conversation: Conversation;
  style?: React.CSSProperties;
}

export default function ConversationContainer({ conversation, style }: ConversationContainerProps) {
  const [isClosingConversation, setIsClosingConversation] = useState<boolean>(false);
  const [isConversationClosed, setIsConversationClosed] = useState<boolean>(
    !!conversation.finishedAt,
  );
  const [showCloseConversationMotivePromptModal, setShowCloseConversationMotivePromptModal] =
    useState<boolean>(false);
  const [closeConversationMotiveTextareaValue, setCloseConversationMotiveTextareaValue] =
    useState<string>('');
  const { addToast } = useToasts();

  function getMessageOrConversationDate() {
    if (conversation.messages[0]) {
      return new Date(conversation.messages[0]?.timestamp);
    } else {
      return new Date(conversation.createdAt);
    }
  }

  // #region CLOSE CONVERSATION MODAL
  function handleCloseConversationButtonClick() {
    if (isClosingConversation) {
      return;
    }
    setShowCloseConversationMotivePromptModal(false);
    setIsClosingConversation(true);
    APIClient.post(`/conversations/${conversation.id}/close`, {
      closingMotive: closeConversationMotiveTextareaValue,
    })
      .then((res) => {
        addToast('Conversación finalizada.', {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setIsConversationClosed(true);
      })
      .catch((err) => {
        addToast(err?.response?.data.message ?? 'Ocurrió un error al cerrar la conversación.', {
          appearance: 'warning',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setIsConversationClosed((err?.response?.data.status ?? 0) === 409);
      })
      .finally(() => {
        setIsClosingConversation(false);
      });
  }

  function handleCloseConversationMotiveTextareaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCloseConversationMotiveTextareaValue(e.target.value);
  }

  function handleOpenModalButtonClick() {
    setShowCloseConversationMotivePromptModal(true);
  }

  function handleCloseModalButtonClick() {
    setShowCloseConversationMotivePromptModal(false);
  }

  const renderCloseConversationMotivePromptModal = () => (
    <Modal
      size="lg"
      show={showCloseConversationMotivePromptModal}
      onHide={handleCloseModalButtonClick}>
      <Modal.Header closeButton>
        <Modal.Title>Motivo cierre conversación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <textarea
          className="w-100"
          style={{ height: '10rem' }}
          maxLength={200}
          onChange={handleCloseConversationMotiveTextareaChange}
          value={closeConversationMotiveTextareaValue}
          placeholder="Puede ingresar el motivo o dejar en blanco este campo"
        />
        <div className="w-100 text-muted text-end">
          {closeConversationMotiveTextareaValue.length}/200
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModalButtonClick}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleCloseConversationButtonClick}>
          Cerrar conversación
        </Button>
      </Modal.Footer>
    </Modal>
  );
  // #endregion

  return (
    <>
      {showCloseConversationMotivePromptModal && renderCloseConversationMotivePromptModal()}
      <div className="conversation-container" style={style}>
        <p className="messages-header">
          Conversación con:&nbsp;
          <span>{conversation.messages[0]?.senderIdentifier}</span>
          &nbsp;|&nbsp;
          <span>
            {new Intl.DateTimeFormat('es-ES', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }).format(getMessageOrConversationDate())}
          </span>
          {!isConversationClosed && (
            <Button
              variant="danger"
              size="sm"
              className="close-conversation-button"
              onClick={handleOpenModalButtonClick}
              disabled={isClosingConversation}>
              <span>
                <FontAwesomeIcon
                  icon={isClosingConversation ? faSpinner : faCommentSlash}
                  title="Cerrar conversación"
                  fixedWidth
                  size="xs"
                  spin={isClosingConversation}
                />
              </span>
            </Button>
          )}
        </p>
        <div className="messages-container">
          {conversation.messages?.toReversed().map((message) => (
            <ConversationMessageBubble key={message.id} message={message} />
          ))}
        </div>
      </div>
    </>
  );
}
