import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import APIClient from '../services/APIClient';

import { Conversation } from '../types/model';
import ConversationContainer from './ConversationContainer';
import config from '../config';

export default function ConversationManager() {
  const [conversation, setConversation] = useState<Conversation>();
  const intervalIdRef = useRef<NodeJS.Timeout>();
  const params = useParams();

  useEffect(() => {
    if (!intervalIdRef.current || params.id !== conversation?.id) {
      setConversation(undefined);
      loadConversation();
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
      intervalIdRef.current = setInterval(
        loadConversation,
        parseInt(config.conversation.messageUpdateInterval!),
      );
    } 
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
  }, [params.id]);

  useEffect(() => {
    if (intervalIdRef.current && conversation?.finishedAt) {
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = undefined;
    }
  }, [conversation]);

  async function loadConversation() {
    APIClient.get(`/conversations/${params.id}`).then((res) => {
      setConversation(res.data.data);
    });
  };

  return conversation ? (
    <ConversationContainer conversation={conversation} style={{ width: '55vw' }} />
  ) : (
    <span className="m-auto w-fit fs-3">Cargando conversación...</span>
  );
}
