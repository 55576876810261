import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import { EntityEditForm } from '../../components';
import APIClient from '../../services/APIClient';
import { ProductCategory1, Props } from '../Interfaces/interfaces';
import { ProductCategory1EditState } from './productCategory1Interface';
import FormInput from '../../components/componentsTs/FormInput';
import { WithRouterProps, withRouter } from '../withRouter';

class ProductCategory1Edit extends React.Component<WithRouterProps, ProductCategory1EditState> {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: WithRouterProps) {
    super(props);

    const { id } = props.params;

    this.state = {
      id,
      isAdding: typeof id === 'undefined',
      entity: {},
      isLoading: true,
    };
  }

  onRetrieveEntity = async () => {
    const { id } = this.state;

    // get coleccione
    const productCategories1Res = await APIClient.get(`/product-categories-1/${id}`);

    this.setState({
      isLoading: false,
      entity: productCategories1Res.data.data,
    });
    return productCategories1Res.data.data;
  };

  /**
   * Save the item
   */
  onSaveEntity = async (entityToSave: ProductCategory1) => {
    const { id, isAdding } = this.state;
    const { navigate, toastManager } = this.props;

    let saveResponse: AxiosResponse;
    if (isAdding) {
      saveResponse = await APIClient.post('/product-categories-1', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/product-categories-1/${id}`, entityToSave);
    }

    navigate('/product-categories-1');
    toastManager.add(`Categoría 1 ${saveResponse.data.data.code} guardada con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });

    return saveResponse.data.data;
  };

  render() {
    const { id, entity, isAdding } = this.state;

    return (
      <div>
        <h1 className="page-title">{isAdding ? 'Nueva Categoría 1' : `Categoría 1 #${id}`}</h1>

        <EntityEditForm
          onRetrieveEntity={this.onRetrieveEntity}
          onSaveEntity={this.onSaveEntity}
          addMode={isAdding}>
          <>
            <Row>
              <Col md={6}>
                <FormInput
                  id="code"
                  label="Código"
                  type="text"
                  defaultValue={entity.code}
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormInput
                  id="description"
                  label="Descripción"
                  type="text"
                  defaultValue={entity.description}
                  required
                />
              </Col>
              <Col md={6}>
                <FormInput
                  id="webDescription"
                  label="Descripción Web"
                  type="text"
                  defaultValue={entity.webDescription}
                />
              </Col>
            </Row>
          </>
        </EntityEditForm>
      </div>
    );
  }
}

export default withToastManager(withRouter(ProductCategory1Edit));
