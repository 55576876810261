import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import debounce from 'lodash.debounce';
import { isArrayEqual, isObjectEqual } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagic,
} from '@fortawesome/free-solid-svg-icons';

interface SelectCustomEventArg {
  target: {
    id: string | null;
    value: string | null;
    options: SelectCustomOption[];
  };
}

export interface SelectCustomOption {
  value: string;
  label: string;
}

interface SelectCustomProps {
  id: string;
  label: string;
  disabled?: boolean;
  clearable?: boolean;
  onLoadOptions?: (query: string, options?: SelectCustomOption[]) => Promise<SelectCustomOption[]>;
  onChange?: (e: SelectCustomEventArg) => any;
  options?: SelectCustomOption[] | null;
  selectedOption?: SelectCustomOption | null;
  aiStatusIconColor?: string;
  aiInterpretDescription?: string;
}

const onLoadOptionsDefault = async (query: string, options?: SelectCustomOption[]) => {
  if (options === undefined) {
    return [];
  }

  const puntosEntregaFiltered = options.filter((point) =>
    point.label.toLowerCase().includes(query.toLowerCase()),
  );

  return puntosEntregaFiltered;
}

export default function FormSelectCustom({
  id,
  label,
  disabled = false,
  clearable = false,
  onLoadOptions = onLoadOptionsDefault,
  onChange,
  options: externalOptions,
  selectedOption: selectedOptionProp,
  aiStatusIconColor,
  aiInterpretDescription,
}: SelectCustomProps) {
  const [options, setOptions] = useState<SelectCustomOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<SelectCustomOption | null>(null);
  const [selectedOptionByProp, setSelectedOptionByProp] = useState<SelectCustomOption | null>(null);
  const [oldSelectedOptionValue, setOldSelectedOptionValue] = useState<string>(selectedOptionProp?.value ?? '');
  const [isMinChars, setIsMinChars] = useState<boolean>(false);
  const input = useRef<HTMLInputElement | null>(null);
  const { addToast } = useToasts();

  useEffect(() => {
    // Ensures that the default value is set and the options are not undefined/null
    if (
      selectedOptionProp === undefined ||
      selectedOptionProp?.value === undefined ||
      selectedOptionProp?.label === undefined
    ) {
      return;
    }
    setSelectedOption(selectedOptionProp);
    setSelectedOptionByProp(selectedOptionProp);
    setOldSelectedOptionValue(selectedOptionProp.value);

    if (
      (!Array.isArray(externalOptions)) ||
      (Array.isArray(externalOptions) && externalOptions.length === 0)
    ) {
      return;
    }
    setOptions(externalOptions);
  }, []);

  useEffect(() => {
    // Workaround to generate a onChange event when the selected option changes
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )!.set;

    if (input.current != null) {
      // set the value in the input
      nativeInputValueSetter!.call(input.current, selectedOption?.value ?? '');

      if ((selectedOption?.value ?? '') != oldSelectedOptionValue) {
        // generate input event for compatibility
        input.current.dispatchEvent(new Event('input', { bubbles: true }));
        
        if (selectedOption == null && onChange) {
          // if options cleared, execute onChange
          onChange({ target: { id: id, value: null, options} });
        }

        setOldSelectedOptionValue(selectedOption?.value ?? '');
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    // Set the default options to show in the select
    if (externalOptions && isArrayEqual(options, externalOptions)) {
      return;
    }
    setOptions(externalOptions ?? []);
    setSelectedOptionByProp(selectedOptionProp ?? null);
    setSelectedOption(null);
  }, [externalOptions]);

  useEffect(() => {
    // Set the selected option to show in the select
    if (selectedOptionProp === null) {
      setSelectedOption(null);
      setSelectedOptionByProp(null);
      return;
    }

    if (
      selectedOptionProp === undefined ||
      selectedOptionProp?.value === undefined ||
      selectedOptionProp?.label === undefined
    ) {
      return;
    }

    if (isObjectEqual(selectedOptionProp, selectedOptionByProp)) {
      return;
    }
    setSelectedOption(selectedOptionProp);
  
  }, [selectedOptionProp]);

  const aiTooltip = (
    <Tooltip>{aiInterpretDescription}</Tooltip>
  );

  const loadOptionsHandler = (query: string, loader) => {
    // if the query is less than 3 char, return the default options
    if (query.length < 3) {
      setIsMinChars(false);
      if (externalOptions === undefined) {
        setOptions([]);
      }
      loader([]);
      return;
    }

    setIsMinChars(true);
    // executes the promise and load the results or return []
    onLoadOptions(query, options)
      .then((optionsFormatted) => {
        if (externalOptions === undefined) {
          setOptions(optionsFormatted);
        }
        loader(optionsFormatted);
      })
      .catch((error: any) => {
        addToast(`Hubo un problema al cargar los datos: "${error.message}"`, {
          appearance: 'error',
        });
        loader([]);
      });
  };

  const selectChangeHandler = (option: SelectCustomOption | null) => {
    // When the selected option changes, set the value of the input
    // and call the onChange callback if there is one provided.
    setSelectedOption(option);
    if (onChange) {
      if (option === null) {
        onChange({ target: { id: id, value: null, options } });
        return;
      }
      onChange({ target: { id: id, value: option.value, options } });
    }
  };

  return (
    <Form.Group className="mt-3" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <input style={{ display: 'none' }} name={id} type="text" ref={input} readOnly />
      <div className='d-flex align-items-center justify-content-between'>
        <AsyncSelect
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              color: state.isDisabled ? 'black !important' : 'inherit',
            })
          }}
          id={id}
          placeholder="Seleccione opción"
          isDisabled={disabled}
          noOptionsMessage={() => {
            return isMinChars ? 'Sin resultados' : 'Escriba por lo menos 3 caracteres';
          }}
          loadingMessage={() => 'Buscando...'}
          cacheOptions={false}
          isSearchable
          isClearable={clearable}
          defaultOptions={options}
          loadOptions={debounce(loadOptionsHandler, 400)}
          value={selectedOption ?? null}
          onChange={selectChangeHandler}
          onBlur={() => setIsMinChars(false)}
        />

        {aiInterpretDescription && (
          <OverlayTrigger overlay={aiTooltip}>
            <div className='ms-2'>
              <FontAwesomeIcon icon={faMagic} color={aiStatusIconColor} fixedWidth/>
            </div>
          </OverlayTrigger>
        )}
      </div>
    </Form.Group>
  );
}
