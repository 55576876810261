import React from 'react';
import { Route } from 'react-router-dom';
import ListaPrecioList from './ListaPrecioList';
import ListaPrecioEdit from './ListaPrecioEdit';

const routes = () =>
  [
    // <Route path="/listas-precio/nuevo" element={<ListaPrecioEdit />} key="/listas-precio/nuevo" />,
    <Route path="/listas-precio/:id" element={<ListaPrecioEdit />} key="/listas-precio/:id" />,
    <Route path="/listas-precio" element={<ListaPrecioList />} key="/listas-precio" />,
  ].map((route) => route);

export default routes;
