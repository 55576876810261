import React from 'react';
import { Route } from 'react-router-dom';
import ProductCategory2Edit from './productCategory2Edit';
import ProductCategory2List from './productCategory2List';

const routes = () =>
  [
    <Route
      path="/product-categories-2/new"
      element={<ProductCategory2Edit />}
      key="/product-categories-2/new"
    />,
    <Route
      path="/product-categories-2/:id(\d+)"
      element={<ProductCategory2Edit />}
      key="/product-categories-2/:id"
    />,
    <Route
      path="/product-categories-2"
      element={<ProductCategory2List />}
      key="/product-categories-2"
    />,
  ].map((route) => route);

export default routes;
