import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import { EntityEditForm, FormInputField } from '../../components';
import APIClient from '../../services/APIClient';
import { ProductCategory3 } from '../Interfaces/interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { WithRouterProps } from '../withRouter';

function ProductCategory3Edit({ toastManager }: WithRouterProps) {
  const { id } = useParams<{ id: string }>();
  const [description, setDescription] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [isAdding, setIsAdding] = useState(typeof id === 'undefined');

  const navigate = useNavigate();

  const onRetrieveEntity = async () => {
    try {
      const productCategory3Res = await APIClient.get(`/product-categories-3/${id}`);
      const entity = productCategory3Res.data.data as ProductCategory3;
      setDescription(entity.description);
    } catch (error) {
      handleErrorMessage('Error in on retrieve ProductCategory3', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAdding) {
      onRetrieveEntity();
    } else {
      setIsLoading(false);
    }
  }, [id]);

  const handleErrorMessage = (message: string, error: unknown) => {
    console.error(message, error);

    toastManager.add(`Ocurrió un error: "${error}"`, {
      appearance: 'error',
    });
  };

  const onSaveEntity = async (entityToSave: ProductCategory3) => {
    try {
      let saveResponse: AxiosResponse;

      if (isAdding) {
        saveResponse = await APIClient.post('/product-categories-3', entityToSave);
      } else {
        saveResponse = await APIClient.patch(`/product-categories-3/${id}`, entityToSave);
      }

      toastManager.add(
        `Categoría 3 ${saveResponse.data.data.id} guardada con éxito`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
        () => navigate('/product-categories-3'),
      );

      return saveResponse.data.data;
    } catch (error) {
      handleErrorMessage('Error in save ProductCategory3', error);
    }
  };

  return (
    <div>
      <h1 className="page-title">{isAdding ? 'Nueva Categoría 3' : `Categoría 3 #${id}`}</h1>

      <EntityEditForm
        onRetrieveEntity={onRetrieveEntity}
        onSaveEntity={onSaveEntity}
        addMode={isAdding}>
        <Row>
          <Col>
            <FormInputField
              id="description"
              label="Descripción"
              as="input"
              defaultValue={description}
              required
            />
          </Col>
        </Row>
      </EntityEditForm>
    </div>
  );
}

export default withToastManager(ProductCategory3Edit);
