import React, { useRef, useEffect, useState} from 'react'
import { useToasts  } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRobot,
  faTrashAlt,
  faUndo,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

interface ProductAiTagItemTableRowProps {
  id: number | null;
  index: number;
  name: string;
  productId: number | null;
  orderId: number | null;
  onEdit: (index: number, name: string) => void;
  onDelete: (index: number) => void;
  onConfirm: (index: number) => void;
};

export default function ProductAiTagItemTableRow({
  id,
  index,
  name,
  productId,
  orderId,
  onEdit,
  onDelete,
  onConfirm,
}: ProductAiTagItemTableRowProps) {
  const [undoName, setUndoName] = useState<string>(name ?? '');
  const [isEdit, setIsEdit] = useState<boolean>(name === '' ? true : false);
  const inputRef = useRef<HTMLInputElement>(null);
  const blurTimeout = useRef<any>(null);
  const { addToast } = useToasts();

  useEffect(() => {
    if (isEdit) {
      // set focus on input and saves current name
      inputRef.current!.focus();
      setUndoName(name);
    } else if (name !== undoName) {
      onConfirm(index);
    }
  }, [isEdit]);

  const handleSpanNameDblClick = () => {
    // start editing with double click
    if (orderId) {
      addToast('No se puede editar una etiqueta IA automática.', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }
    setIsEdit(true);
  };

  const handleInputNameKeyDown = (event) => {
    if (event.keyCode === 13) {
      // if enter, save
      if (inputRef.current!.value.trim() === '') {
        return;
      }
      setIsEdit(false);
    } else if (event.keyCode === 27) {
      // if escape, cancel edit
      cancelEdit();
    }
  };
  const handleInputNameBlur = () => {
    if (inputRef.current!.value.trim() === '') {
      return;
    }
      // workaround to allow undo to be pressed
    blurTimeout.current = setTimeout(() => {
      setIsEdit(false);
    }, 100);
  };

  const handleInputNameChange = (event) => {
    onEdit(index, event.target.value)
  };
    
  const handleCancelEditTagClick = () => {
    cancelEdit();
  };

  const handleDeleteTagClick = () => {
    onDelete(index);
  };

  const cancelEdit = () => {
    if (id === null) {
      // delete tag without api call
      onDelete(index);
      return;
    }

    // undo name edit
    clearTimeout(blurTimeout.current);
    onEdit(index, undoName);
    setIsEdit(false);
  };

  // TODO: auto cancel timer

  return (
    <tr>
      <td className="text-center">
        <FontAwesomeIcon
          fixedWidth
          icon={orderId ? faRobot : faUser}
          size="sm"
          title={`Creación ${orderId ? 'automática' : 'manual'}`}
        />
      </td>
      <td>
        {!isEdit ? (
          <span onDoubleClick={handleSpanNameDblClick}>{name}</span>
        ) : (
          <input
            style={{ width: '80%' }}
            placeholder="Use un nombre con el cual los clientes se refieren a este producto"
            onKeyDown={handleInputNameKeyDown}
            onBlurCapture={handleInputNameBlur}
            onChange={handleInputNameChange}
            value={name}
            ref={inputRef}
          />
        )}
      </td>
      <td>
        <div className="d-flex align-items-center justify-content-end gap-3">
          {id && isEdit && (
            <FontAwesomeIcon
              fixedWidth
              icon={faUndo}
              onClick={handleCancelEditTagClick}
              size="xs"
              title="Cancelar edición"
            />
          )}
          <FontAwesomeIcon
            fixedWidth
            icon={faTrashAlt}
            onClick={handleDeleteTagClick}
            size="xs"
            title="Eliminar etiqueta"
          />
        </div>
      </td>
    </tr>
  );
}
