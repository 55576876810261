import React from 'react';
import { Route } from 'react-router-dom';
import PedidoList from './OrderList';
import PedidoEdit from './OrderEdit';
import OrderAIEdit from './OrderAIEdit';

const routes = () =>
  [
    // <Route path="/pedidos/nuevo" exact element={<PedidoEdit />} key="/pedidos/nuevo" />,
    <Route path="/pedidos/:id" element={<PedidoEdit />} key="/pedidos/:id" />,
    <Route path="/orders/:id" element={<OrderAIEdit />} key="/orders/:id" />,
    <Route path="/pedidos" element={<PedidoList />} key="/pedidos" />,

  ].map((route) => route);

export default routes;
