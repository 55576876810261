import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface LoadingProps {
  className?: string;
}

/**
 * Functional component for displaying a loading indicator.
 *
 * @param {string} [className="p-3"] - the class name for the loading container.
 * @return {ReactElement} the loading component.
 */
export default function Loading({ className = 'p-3' }: LoadingProps) {
  return (
    <div className={className}>
      <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="me-1" />
      <span>Cargando...</span>
    </div>
  );
}
