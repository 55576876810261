import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

interface DataTableSearchFieldProps {
  onSearch: Function;
  onClear: Function;
}

export default function DataTableSearchField({ onSearch, onClear }: DataTableSearchFieldProps) {
  const [searchText, setSearchText] = useState<string>('');

  function handleInputChange(event) {
    const { value } = event.target;
    setSearchText(value);
  }

  function handleKeyPress(event) {
    if (event.keyCode === 13) {
      onSearch(searchText.trim());
    }
  }

  function handleSearchClick() {
    onSearch(searchText.trim());
  }

  function handleClearClick() {
    setSearchText('');
    onClear();
  }

  return (
    <InputGroup size="sm">
      <Button className="btn btn-primary" title="Filtrar" onClick={handleSearchClick}>
        <FontAwesomeIcon icon={faSearch} />
      </Button>

      <FormControl
        placeholder="Filtrar registros"
        name="searchText"
        value={searchText}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
      />
      <Button className="btn btn-secondary" title="Limpiar" onClick={handleClearClick}>
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </InputGroup>
  );
}
