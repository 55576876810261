import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';

function ZonaList({ navigate, params, toastManager }) {
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [zonas, setZonas] = React.useState([]);

  React.useEffect(() => {
    loadZonas();
  }, []);

  function loadZonas() {
    APIClient.get('/zonas')
      .then((res) => {
        setIsDataLoading(false);
        setZonas(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        setIsDataLoading(false);
      });
  }

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: false,
      hidden: true,
    },
    {
      dataField: 'descripcion',
      text: 'Descripción',
      sort: true,
    },
    // {
    //   dataField: 'actions',
    //   isDummyField: true,
    //   text: '',
    //   formatter: (cellContent, row) => (
    //     <ButtonToolbar>
    //       <ButtonGroup>
    //         <LinkContainer to={`/zonas/${row.id}`}>
    //           <Button size="sm" variant="outline-primary" title="Editar">
    //             <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
    //           </Button>
    //         </LinkContainer>
    //       </ButtonGroup>
    //     </ButtonToolbar>
    //   ),
    // },
  ];

  /**
   * Columns for export
   */
  const exportColumns = [
    {
      fieldName: 'id',
      title: 'ID',
    },
    {
      fieldName: 'descripcion',
      title: 'Descripción',
    },
  ];

  return (
    <div>
      <h1 className="page-title">Zonas</h1>

      <DataTable
        columns={columns}
        data={zonas}
        isDataLoading={isDataLoading}
        keyField="id"
        // addButton="/zonas/nueva"
        exportConfig={{
          exportURL: '/zonas/export.xlsx',
          columns: exportColumns,
        }}
      />
    </div>
  );
}

export default withToastManager(ZonaList);
