import React, { useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import config from '../../config';
import { EntityEditForm, FormCheckField } from '../../components';
import ProductAiTagItemTableRow from '../../components/ProductAiTagItemTableRow';
import Utils from '../Utils';
import APIClient from '../../services/APIClient';
import { Imagen, PosicionChanges, ProductAiTagState } from './productInterface';
import FormSelect from '../../components/componentsTs/FormSelect';
import FormInput from '../../components/componentsTs/FormInput';
import { WithRouterProps, withRouter } from '../withRouter';
import {
  ProductCategory1,
  ProductCategory2,
  Marca,
  Proveedor,
  ProductType,
} from '../Interfaces/interfaces';

function ProductEdit(props: WithRouterProps) {
  const [entity, setEntity] = useState<any | object>({});
  const [images, setImages] = useState<Imagen[]>([]);
  const [isAdding, setIsAdding] = useState<boolean>(typeof props.params.id === 'undefined');
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

  const [brands, setBrands] = useState<Marca[]>([]);
  const [productCategories2, setProductCategories2] = useState<ProductCategory2[]>([]);
  const [productCategories1, setProductCategories1] = useState<ProductCategory1[]>([]);
  const [positionChanges, setPositionChanges] = useState<PosicionChanges[]>([]);
  const [selectedImage, setSelectedImage] = useState<Partial<Imagen>>({});
  const [productTypes, setProductTypes] = useState<ProductType[]>([]);
  const [imageToDelete, setImageToDelete] = useState<Imagen>({});
  const [suppliers, setSuppliers] = useState<Proveedor[]>([]);
  const [showDeleteImageModal, setShowDeleteImageModal] = useState<boolean>(false);
  const [aiTags, setAiTags] = useState<ProductAiTagState[]>([]);
  const [aiTagIndexToDelete, setAiTagIndexToDelete] = useState<number | null>(null);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState<boolean>(false);
  const { navigate, toastManager } = props;
  const { id } = props.params;
  const pageNumbUrl = Utils.sanitizeQuery(['page'], props.location.search).page;
  const pageNum = parseInt(pageNumbUrl);
  const page = Utils.isPositiveInteger(pageNum) ? pageNum : 1;

  /**
   * Get img array, selectedImage and imageToDelete from state and remove matching image from array.
   * If image deleted was default image, add first image as default. Save to state.
   */
  const deleteFile = async () => {
    let newImages = [...images];
    try {
      if (imageToDelete.id) {
        // delete image from API.
        await APIClient.delete(`/products/${id}/images/${imageToDelete.id}`);
      }

      // get image index
      const imgIndex = newImages.findIndex((img) => img.posicion === imageToDelete.posicion);
      let newPositionChanges: PosicionChanges[] = [];
      if (newImages.length > 1) {
        // update posicion for each consecutive element and push changes into array.
        for (let i = imgIndex + 1; i < newImages.length; i += 1) {
          const imagen: any = newImages[i];
          imagen.posicion -= 1;
          newPositionChanges.push({ id: imagen.id, posicion: imagen.posicion });
        }
        newImages.splice(imgIndex, 1);
      } else {
        // images array has only one element, no posicion updates are necessary.
        newImages.pop();
        newPositionChanges = [];
      }
      setImages(newImages);
      setImageToDelete({});
      setShowDeleteImageModal(false);
      setPositionChanges(newPositionChanges);

      toastManager.add('La imagen fue eliminada correctamente.', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${error}"`, {
        appearance: 'error',
      });
    } finally {
      // Despite being called after setImages, thanks to useState async, images array haven't been updated yet.
      setSelectedImage(images[0]);
    }
  };

  /**
   * Create JSX elements for uploaded and to be uploaded images.
   * Un-uploaded images have a tooltip to indicate they haven't been uploaded yet.
   * @return image JSX elements
   */
  const generateImagesElem = () => {
    return (
      <Row>
        {images.map((img, i) => {
          if (img.isUploaded) {
            return (
              <Col key={`img_${img.id}`} md={3}>
                <Container
                  style={{ height: '250px' }}
                  className="d-flex justify-content-center align-items-center img-thumbnail"
                  onClick={() => setSelectedImage(img)}>
                  <Image
                    className="mh-100"
                    id={`img_${i}`}
                    src={`${config.api.productImagesBasePath}/${img.productId}/${img.filename}`}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </Container>
              </Col>
            );
          }
          return (
            <OverlayTrigger
              key={`img_${i}`}
              overlay={<Tooltip id="tooltip-disabled">Aún no ha sido subida al servidor!</Tooltip>}>
              <Col md={3}>
                <div
                  style={{ height: '250px' }}
                  className="bg-warning d-flex justify-content-center align-items-center img-thumbnail">
                  <Image
                    className="mh-100"
                    //disabled
                    style={{ opacity: '0.6' }}
                    id={`img_${i}`}
                    src={`${img.filename}`}
                    onClick={() => setSelectedImage(img)}
                    alt={`imagen_${i}`}
                    fluid
                  />
                </div>
              </Col>
            </OverlayTrigger>
          );
        })}
      </Row>
    );
  };

  /**
   * Create temporary URL for new file and add it into images array.
   */
  const handleImageChange = (event: any) => {
    const newFile = event.target.files[0];

    if (newFile) {
      if (newFile.size > 5000000) {
        toastManager.add('Solo se permiten imágenes de menos de 5 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        const newImage: Imagen = {};
        const fileTempUrl = URL.createObjectURL(newFile);

        const newImages = [...images];
        newImage.filename = fileTempUrl;

        if (images.length > 0) {
          const imgPositions = images.map((img: any) => img.posicion);
          const maxPosition = Math.max.apply(null, imgPositions) + 1;
          newImage.posicion = maxPosition;
          newImages.push(newImage);
        } else {
          newImage.posicion = 0;
          newImages.push(newImage);
          setSelectedImage(newImage);
        }
        setImages(newImages);
      }
    }
  };

  const onLoadForm = async () => {
    setIsDataLoading(true);
    // get marcas
    const brandsRes = await APIClient.get('/marcas');
    // get lineas
    const productCategories2Res = await APIClient.get('/product-categories-2');
    // get co1ecciones
    const productCategories1Res = await APIClient.get('/product-categories-1');
    // get tipologias
    const productTypesRes = await APIClient.get('/product-types');
    // get proveedores
    const suppliersRes = await APIClient.get('/proveedores');

    setBrands(brandsRes.data.data);
    setProductCategories2(productCategories2Res.data.data);
    setProductCategories1(productCategories1Res.data.data);
    setProductTypes(productTypesRes.data.data);
    setSuppliers(suppliersRes.data.data);
  };

  const onRetrieveEntity = async () => {
    // get product
    const productRes = await APIClient.get(`/products/${id}`);
    const product = productRes.data.data;
    const { images: productImages } = product;

    productImages.forEach((img: any) => {
      img.isUploaded = true;
    });
    productImages.sort((a: any, b: any) => a.posicion - b.posicion);

    let localAiTags = [...product.aiTags];
    localAiTags = localAiTags.map((aiTag: any) => {
      aiTag.indexKey = Math.random().toString();
      return aiTag;
    });

    setEntity(product);
    setImages(productImages);
    setSelectedImage(productImages[0]);
    setAiTags(localAiTags);
    setIsDataLoading(false);

    return product;
  };

  /**
   * Save the item
   */
  const onSaveEntity = async (entityToSave: any) => {
    // Retrieve images to upload
    const imgsToUpload = images.filter((img) => !img.isUploaded);
    let saveResponse: any = null;
    if (isAdding) {
      saveResponse = await APIClient.post('/products', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/products/${id}`, entityToSave);
    }

    if (imgsToUpload.length > 0) {
      const imgPromises: any = [];
      for (let i = 0; i < imgsToUpload.length; i += 1) {
        const curImg = imgsToUpload[i];
        // Get image object
        const curImgResponse = await APIClient.getImage(curImg.filename, { responseType: 'blob' });
        const newImage: any = {};

        const reader: any = new FileReader();
        reader.onloadend = () => {
          const result: any = reader.result.split(',');
          newImage.fileData = result[1];
          newImage.fileType = result[0].match(/image\/.*g/g)[0];
          newImage.isUploaded = false;
          newImage.posicion = curImg.posicion;
          const curPromise = APIClient.post(
            `/products/${saveResponse.data.data.id}/images`, // HERE
            newImage,
          );
          imgPromises.push(curPromise);
        };
        reader.onerror = (error: any) => {
          console.error('Error: ', error);
        };
        reader.readAsDataURL(curImgResponse.data);
      }
      await Promise.all(imgPromises);
    }
    // '/products?limit=10&sortField=id&sortDir=desc&offset=0&excludeAssocFields=imagenes'
    navigate(`/products?page=${page}`);
    toastManager.add(`Producto ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const prepareToSave = (entityToSave: any) => {
    if (positionChanges.length > 0) {
      entityToSave.imagenes = positionChanges;
    }
    return entityToSave;
  };

  const setImageAsDefault = () => {
    // get old posicion value
    const { posicion } = selectedImage;
    // find existing default image
    const defaultImage: Imagen = images[0];
    // if there is and it is not the selected image, then change
    // its posicion to selectedImages old posicion
    if (defaultImage && defaultImage.id !== selectedImage.id) {
      defaultImage.posicion = posicion;
    }

    // selectedImage.posicion = 0;
    const tempPositionChanges: PosicionChanges[] = [];
    if (selectedImage.id) {
      tempPositionChanges.push({ id: selectedImage.id, posicion: selectedImage.posicion });
    }
    tempPositionChanges.push({ id: defaultImage.id, posicion: defaultImage.posicion });

    let sortedImages = [...images];
    const image: any = sortedImages.find((img) => selectedImage.id === img.id);
    image.posicion = 0;
    sortedImages.sort((a: any, b: any) => a.posicion - b.posicion);

    setImages(sortedImages);
    setSelectedImage(sortedImages[0]);
    setPositionChanges(tempPositionChanges);
  };

  const renderDeleteImageModal = () => {
    return (
      <Modal size="sm" show={showDeleteImageModal} onHide={() => setShowDeleteImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>¿Desea eliminar este archivo?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteImageModal(false)}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={deleteFile}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // #region  AI TAGS
  const renderDeleteAiTagModal = () => (
    <Modal size="lg" show={showDeleteTagModal} onHide={handleCloseDeleteAiTagModalButton}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar etiqueta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar ésta etiqueta?</p>
        <p className="text-danger">{aiTags[aiTagIndexToDelete || 0]?.name}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleDeleteAiTagButton}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={handleCloseDeleteAiTagModalButton}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const handleNewAiTagButton = () => {
    const localAiTags = [...aiTags];
    if (localAiTags.length && localAiTags.at(-1)!.name === '') {
      toastManager.add('Complete la etiqueta vacía antes de agregar una nueva.', {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }
    localAiTags.push(createAiTag());
    setAiTags(localAiTags);
  };

  const handleCloseDeleteAiTagModalButton = () => {
    // clears data to delete
    setAiTagIndexToDelete(null);
    setShowDeleteTagModal(false);
  };

  const handleAiTagItemOnDelete = (index: number) => {
    if (aiTags[index].id === null) {
      const localAiTags = [...aiTags];
      localAiTags.splice(index, 1);
      setAiTags(localAiTags);
      return;
    }

    setAiTagIndexToDelete(index);
    setShowDeleteTagModal(true);
  };

  const handleDeleteAiTagButton = async () => {
    const localAiTags: any = [...aiTags];

    // remove aiTag from local copy and extract id
    const aiTagIdToDelete: number = localAiTags.splice(aiTagIndexToDelete!, 1)[0].id;

    if (aiTagIdToDelete === null) {
      setAiTags(localAiTags);
    } else {
      let deleteAiTagData: any;
      try {
        deleteAiTagData = (await APIClient.delete(`/products/${id}/ai-tags/${aiTagIdToDelete}`))
          .data.data;
        toastManager.add('Etiqueta IA eliminada.', {
          appearance: 'success',
          autoDismiss: true,
        });
        setAiTags(localAiTags);
      } catch (error) {
        toastManager.add('Hubo un error al eliminar la etiqueta.', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }

    setShowDeleteTagModal(false);
    setAiTagIndexToDelete(null);
  };

  const handleAiTagItemOnEdit = (index: number, name: string) => {
    const localAiTags = [...aiTags];
    localAiTags[index].name = name;
    setAiTags(localAiTags);
  };

  const handleAiTagItemOnConfirm = async (index: number) => {
    const localAiTags = [...aiTags];
    const aiTag = localAiTags[index];

    const aiTagCleaned = { ...aiTag };
    delete aiTagCleaned.indexKey;

    try {
      if (aiTag.id === null) {
        const createdAiTagData = (await APIClient.post(`/products/${id}/ai-tags`, aiTagCleaned))
          .data.data;
        toastManager.add('Etiqueta creada.', {
          appearance: 'success',
          autoDismiss: true,
        });
        aiTag.id = createdAiTagData.aiTag.id;
      } else {
        const modifyedAiTagData = (
          await APIClient.patch(`/products/${id}/ai-tags/${aiTag.id}`, aiTagCleaned)
        ).data.data;
        toastManager.add('Etiqueta actualizada.', {
          appearance: 'success',
          autoDismiss: true,
        });
      }
    } catch (error) {
      toastManager.add(`Hubo un error al ${aiTag.id ? 'actualizar' : 'crear'} la etiqueta.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    setAiTags(localAiTags);
  };

  const createAiTag = (): ProductAiTagState => ({
    id: null,
    name: '',
    orderId: null,
    productId: id,
    indexKey: Math.random().toString(),
  });
  // #endregion AI Tags

  return (
    <>
      {renderDeleteImageModal()}
      {renderDeleteAiTagModal()}
      <h1 className="page-title">
        {isAdding
          ? 'Producto nuevo'
          : `Producto ${!isDataLoading ? (entity.erpCodigo ? `(Cod: ${entity.erpCodigo})` : `#${id}`) : ''}`}
      </h1>

      <EntityEditForm
        onLoadForm={onLoadForm}
        onRetrieveEntity={onRetrieveEntity}
        onSaveEntity={onSaveEntity}
        addMode={isAdding}
        prepareToSave={prepareToSave}>
        <>
          <Row>
            <Col md={6}>
              <FormInput
                disabled={true}
                id="descripcion"
                label="Descripción"
                type="text"
                defaultValue={entity.descripcion}
                required
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="marcaCodigo"
                label="Marca"
                defaultValue={entity.marcaCodigo}
                choices={brands}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInput
                disabled={true}
                id="erpCodigo"
                label="Código ERP"
                type="text"
                defaultValue={entity.erpCodigo}
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="productCategory2Id"
                label="Categoría principal"
                defaultValue={entity.productCategory2Id}
                choices={productCategories2}
                choiceIdField="id"
                choiceLabelField="descripcion"
                required
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormSelect
                id="proveedorId"
                label="Proveedor"
                defaultValue={entity.proveedorId}
                choices={suppliers}
                choiceIdField="id"
                choiceLabelField="nombre"
                disabled={true}
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="productCategory1Code"
                label="Categoría secundaria"
                defaultValue={entity.productCategory1Code}
                choices={productCategories1}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                disabled={true}
                id="codigoEan13"
                label="Código EAN13"
                type="text"
                defaultValue={entity.codigoEan13}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormSelect
                id="productType"
                label="Tipología"
                defaultValue={entity.productTypeId}
                choices={productTypes}
                choiceIdField="id"
                choiceLabelField="descripcion"
                required
                disabled={true}
              />
            </Col>
            <Col md={3}>
              <FormInput
                id="factorBulto"
                type="number"
                label="Unidades x Bulto:"
                defaultValue={entity ? entity.factorBulto : null}
                min={0}
                step="0.1"
                disabled={true}
              />
            </Col>
            <Col md={3}>
              <FormInput
                id="factorBulto2"
                type="number"
                label="Bultos por Pallet:"
                min={0}
                step="0.1"
                defaultValue={entity ? entity.factorBulto2 : null}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormCheckField id="isPromo" label="Promoción" defaultChecked={entity.isPromo} />
            </Col>
            <Col>
              <FormCheckField id="isNovedad" label="Novedad" defaultChecked={entity.isNovedad} />
            </Col>
            <Col>
              <FormCheckField
                id="isEliminado"
                label="Inactivo"
                defaultChecked={entity.isEliminado}
              />
            </Col>
            <Col>
              <FormCheckField id="isFavorito" label="Favorito" defaultChecked={entity.isFavorito} />
            </Col>
            <Col>
              <FormCheckField id="isAgotado" label="Agotado" defaultChecked={entity.isAgotado} />
            </Col>
            <Col>
              <FormCheckField
                id="isVentaBulto"
                label="Restricción de venta unitaria"
                defaultChecked={entity.isVentaBulto}
              />
            </Col>
            <Col>
              <FormCheckField
                id="isInternal"
                label="Producto interno"
                defaultChecked={entity.isInternal}
              />
            </Col>
          </Row>
          <Card className="mt-3">
            <Card.Body>
              <Card.Title>
                <h2 className="d-flex my-3">Imágenes</h2>
              </Card.Title>
              {selectedImage && images.length > 0 && (
                <Row>
                  <Col className="d-flex align-items-center" md={9}>
                    <Container className="mb-2 border border-primary rounded d-flex justify-content-center">
                      <Image
                        id="img_selected"
                        src={
                          selectedImage.isUploaded
                            ? `http://localhost:3001/public/images/product/${id}/${selectedImage.filename}`
                            : selectedImage.filename
                        }
                        alt={`imagen_${selectedImage.posicion}`}
                        rounded
                        fluid
                      />
                    </Container>
                  </Col>
                  <Col className="d-flex justify-content-center align-items-center" md={3}>
                    <Container className="d-flex flex-column">
                      <Button
                        type="submit"
                        disabled={selectedImage.posicion === 0}
                        className="m-1 bg-primary"
                        onClick={setImageAsDefault}>
                        {selectedImage.posicion === 0 ? 'Predeterminada' : 'Marcar como predet.'}
                      </Button>
                      <Button
                        type="submit"
                        className="m-1 bg-primary"
                        onClick={() => {
                          setShowDeleteImageModal(true);
                          setImageToDelete({ ...selectedImage });
                        }}>
                        Eliminar
                      </Button>
                    </Container>
                  </Col>
                </Row>
              )}
              {images && generateImagesElem()}
            </Card.Body>
            <Card.Footer className="d-flex justify-content-center">
              <Col md={10}>
                <Form.Group className="d-flex align-items-center mb-0" controlId="fileUpload">
                  {/*<label
                    className="custom-file-label position-absolute mb-0"
                    htmlFor="customFileLang">
                    Haga click para seleccionar un archivo.
                  </label> */}
                  <input
                    name="fileUpload"
                    className="custom-file-input"
                    id="customFileLang"
                    lang="es"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
                </Form.Group>
              </Col>
            </Card.Footer>
          </Card>
        </>
      </EntityEditForm>
      <hr />
      <h2 className="d-flex m-0 mt-3">Etiquetas de interpretación</h2>
      <Row>
        <Col>
          <div className="table-responsive-md">
            <Table hover>
              <thead>
                <tr>
                  <th style={{ width: '3rem' }}></th>
                  <th style={{ width: 'auto' }}>Nombre</th>
                  <th style={{ width: '3rem' }}></th>
                </tr>
              </thead>
              <tbody>
                {aiTags &&
                  aiTags.map((tag, index) => (
                    <ProductAiTagItemTableRow
                      key={tag.id ?? tag.indexKey}
                      index={index}
                      id={tag.id}
                      name={tag.name}
                      orderId={tag.orderId}
                      productId={id}
                      onEdit={handleAiTagItemOnEdit}
                      onDelete={handleAiTagItemOnDelete}
                      onConfirm={handleAiTagItemOnConfirm}
                    />
                  ))}
              </tbody>
            </Table>

            <Button variant="outline-primary" onClick={handleNewAiTagButton}>
              Agregar etiqueta
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default withToastManager(withRouter(ProductEdit));
