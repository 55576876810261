import React from 'react';
import { Route } from 'react-router-dom';
import CustomersReport from './CustomersReport';

const routes = () =>
  [
    <Route path="/reporte-clientes" element={<CustomersReport />} key="/reporte-clientes" />,
    <Route
      path="/reporte-clientes/cliente/:id"
      element={<CustomersReport />}
      key="/reporte-clientes/:id"
    />,
  ].map((route) => route);

export default routes;
