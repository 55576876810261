import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import { Button, Col, Collapse, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagic,
  faRobot,
  faTrashAlt,
  faShareSquare,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import APIClient from '../services/APIClient';
import { OrderItem, Product } from '../types/model';
import { formatAmount } from '../views/Utils';

interface OrderItemTableRowProps {
  index: number;
  item: OrderItem;
  disabled: boolean;
  isAiDataVisible: boolean;
  onChange: OnChangePropsEventHandler;
}

interface OnChangePropsEventHandler {
  (index: number, productId: number | null, quantity: number): void;
}
function OrderItemTableRow({
  index,
  item,
  disabled = false,
  isAiDataVisible,
  onChange,
}: OrderItemTableRowProps) {
  const [selectedDescription, setSelectedDescription] = useState({
    value: item.Product?.id ?? null,
    label: item.Product?.descripcion ?? '',
  });
  const [quantity, setQuantity] = useState<number>(item.cantidad ?? 1);
  const [results, setResults] = useState<any>([]);
  const [isFirstRun, setIsFirstRun] = useState<boolean>(true);
  const [isMinChars, setIsMinChars] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  useEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
      return;
    }
    if (selectedDescription.value == null) {
      return;
    }
    onChange(index, +selectedDescription.value, quantity);
  }, [selectedDescription.value, quantity]);

  useEffect(() => {
    if (item?.cantidad === quantity) {
      return;
    }
    setQuantity(item?.cantidad ?? 1);
  }, [item.cantidad, item.productId]);

  let text = 'Item interpretado por IA.';
  let color = 'green';

  if (item.aiData?.matchBy == 'none' && selectedDescription.value === null) {
    text = 'El texto interpretado no se encontró en la base de productos.';
    color = 'tomato';
  } else if (item.productId != item.aiData?.productId || item.cantidad != item.aiData?.quantity) {
    text = 'El item interpretado ha sido modificado manualmente.';
    color = 'cornflowerblue';
  }

  const handleShowProductButtonClick = () => {
    window.open(`${window.location.origin}/products/${item?.Product?.id}`, '_blank');
  };

  const handleOpenModalButtonClick = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModalButtonClick = () => {
    setShowDeleteModal(false);
  };

  const renderDeleteItemModal = () => (
    <Modal size="lg" show={showDeleteModal} onHide={handleCloseModalButtonClick}>
      <Modal.Header closeButton>
        <Modal.Title>Eliminar ítem</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>¿Está seguro de que desea eliminar éste ítem?</p>
        <p className="text-danger">
          {item.id !== null
            ? `${item?.Product?.descripcion ?? item?.aiData?.product} x ${quantity}u`
            : 'Item vacío.'}
        </p>
        <p className="text-muted">
          {item?.aiData?.matchBy && (
            <span>
              Item interpretado <strong>{item.aiData.product}</strong>
              {item?.Product?.id ? '.' : ', no se encontró en la base de datos.'}
            </span>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={() => onChange(index, null, quantity)}>
          Eliminar
        </Button>
        <Button variant="secondary" onClick={handleCloseModalButtonClick}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const loadOptionsHandler = (query: string, loader: any) => {
    // if the query is less than 3, return the default options
    if (query.length < 3) {
      setIsMinChars(false);
      loader([]);
      return;
    }

    setIsMinChars(true);
    // executes the promise and load the results or return []
    onLoadOptions(query)
      .then((optionsFormatted) => {
        loader(optionsFormatted);
      })
      .catch((error: any) => {
        loader([]);
      });
  };

  const onLoadOptions = async (query: string) => {
    const queryEncoded = encodeURIComponent(`%${query}%`);

    const productsData: Product[] = (await APIClient.get(`/products?freeText=${queryEncoded}`)).data
      .data;

    const optionsFormatted = productsData.map((data) => ({
      value: data.id,
      label: `${data.erpCodigo} | ${data.descripcion}`,
    }));

    setResults(optionsFormatted);
    return optionsFormatted;
  };

  const selectDescriptionChangeHandler = (option) => {
    const optionLabel = option.label.split(' | ')[1].trim();
    setSelectedDescription({
      value: option.value,
      label: optionLabel,
    });
  };

  const quantityChangeHandler = (event) => {
    const inputValue = parseInt(event.target.value);
    // if not number or a zero, reject the value
    if (isNaN(inputValue) || inputValue == 0) {
      return;
    }
    setQuantity(inputValue);
  };

  const aiTooltip = <Tooltip>{text}</Tooltip>;
  return (
    <>
      {renderDeleteItemModal()}
      <tr style={{ height: '55px' }}>
        {/* CODE */}
        <td>
          <Col>
            <Row>
              <div className="flex-vertical-center-start" style={{ height: '38px' }}>
                <td>{index + 1}</td>
              </div>
            </Row>
          </Col>
        </td>
        <td>
          <Col>
            <Row>
              <div className="flex-vertical-center-start" style={{ height: '38px' }}>
                <div className={item.aiData === undefined ? 'invisible' : ''}>
                  <OverlayTrigger overlay={aiTooltip}>
                    <div className="me-2">
                      <FontAwesomeIcon icon={faMagic} color={color} fixedWidth />
                    </div>
                  </OverlayTrigger>
                </div>
                <span>{item?.Product?.erpCodigo ?? ''}</span>
              </div>
            </Row>
            {item.aiData?.matchBy === 'code' && (
              <Row className={item.aiData === undefined ? 'invisible' : ''}>
                <Collapse in={isAiDataVisible}>
                  <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                    <FontAwesomeIcon icon={faRobot} fixedWidth />
                    {`${item.aiData.productCode} (${item.aiData.matchPrecision * 100}%)`}
                  </div>
                </Collapse>
              </Row>
            )}
          </Col>
        </td>
        {/* DESCRIPTION */}
        <td>
          <Col>
            <Row className="d-flex justify-content-start">
              <AsyncSelect
                placeholder="Busque un producto..."
                loadingMessage={() => 'Buscando productos...'}
                noOptionsMessage={() => {
                  return isMinChars ? 'Sin resultados' : 'Escriba por lo menos 3 caracteres';
                }}
                cacheOptions={false}
                isSearchable={true}
                isDisabled={disabled}
                loadOptions={debounce(loadOptionsHandler, 400)}
                defaultOptions={results}
                value={selectedDescription.value !== null ? selectedDescription : null}
                onChange={selectDescriptionChangeHandler}
                onBlur={() => setIsMinChars(false)}
                styles={{
                  container: (baseStyles) => ({
                    ...baseStyles,
                    width: '92%',
                    paddingRight: 'unset',
                    fontSize: '12px',
                  }),
                  singleValue: (baseStyles, state) => ({
                    ...baseStyles,
                    color: state.isDisabled ? 'black !important' : 'inherit',
                  }),
                }}
              />
              {item?.Product?.id && (
                <Button
                  variant=""
                  className="hoverIcon hoverIconBlue"
                  style={{ width: '24px' }}
                  onClick={handleShowProductButtonClick}>
                  <FontAwesomeIcon icon={faShareSquare} title="Ver producto" />
                </Button>
              )}
            </Row>
            {(item.aiData?.matchBy === 'description' || item.aiData?.matchBy === 'none') && (
              <Row className={item.aiData === undefined ? 'invisible' : ''}>
                <Collapse in={isAiDataVisible}>
                  <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                    <FontAwesomeIcon icon={faRobot} fixedWidth />
                    {`${item.aiData.product} (${item.aiData.matchPrecision * 100}%)`}
                  </div>
                </Collapse>
              </Row>
            )}
          </Col>
        </td>
        {/* QUANTITY */}
        <td>
          <Col>
            <Row>
              <div className="d-flex justify-content-start align-items-center gap-1">
                <input
                  className="text-center"
                  style={{ width: '30%', height: '38px' }}
                  type="text"
                  value={quantity}
                  disabled={disabled}
                  onChange={quantityChangeHandler}
                />
                <span>{item?.aiData?.unit ? item?.aiData?.unit : 'un'}</span>
              </div>
            </Row>
            {item.aiData?.matchBy && (
              <Row className={item.aiData === undefined ? 'invisible' : ''}>
                <Collapse in={isAiDataVisible}>
                  <div className="flex-vertical-center-start mt-1 text-muted fw-light">
                    <FontAwesomeIcon icon={faRobot} fixedWidth />
                    {item.aiData.quantity}
                  </div>
                </Collapse>
              </Row>
            )}
          </Col>
        </td>
        {/* DELIVERED */}
        <td>
          <span
            style={{ display: 'block', height: '38px', lineHeight: '38px', textAlign: 'start' }}>
            {`${item?.entregado} ${item?.aiData?.unit ? item?.aiData?.unit : 'un'}`}
          </span>
        </td>
        {/* PRICE */}
        <td className="text-end v-middle">
          <span style={{ display: 'block', height: '38px', lineHeight: '38px' }}>
            {formatAmount(item?.precio ?? 0.0)}
            {item.originalPrice && item.originalPrice != item.precio && (
              <>
                <FontAwesomeIcon
                  icon={faWarning}
                  title={`Precio original indicado: ${formatAmount(item.originalPrice)}`}
                  className='ms-1'
                />
              </>
            )}
          </span>
        </td>
        {/* TOTAL */}
        <td className="text-end">
          <span style={{ display: 'block', height: '38px', lineHeight: '38px' }}>
            {formatAmount(quantity * (item?.precio ?? 0.0))}
          </span>
        </td>
        {/* ACTIONS */}
        <td className="text-end v-middle">
          {!disabled && (
            <Button
              variant=""
              className="hoverIcon hoverIconRed"
              onClick={handleOpenModalButtonClick}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
        </td>
      </tr>
    </>
  );
}

export default OrderItemTableRow;
