import React from 'react';
import { Route } from 'react-router-dom';
import ProductCategory1List from './productCategory1List';
import ProductCategory1Edit from './productCategory1Edit';

const routes = () =>
  [
    <Route
      path="/product-categories-1/new"
      element={<ProductCategory1Edit />}
      key="/product-categories-1/new"
    />,
    <Route
      path="/product-categories-1/:id"
      element={<ProductCategory1Edit />}
      key="/product-categories-1/:id"
    />,
    <Route
      path="/product-categories-1"
      element={<ProductCategory1List />}
      key="/product-categories-1"
    />,
  ].map((route) => route);

export default routes;
