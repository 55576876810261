import React from 'react';
import { Route } from 'react-router-dom';
import UsuarioList from './UsuarioList';
import UsuarioEdit from './UsuarioEdit';

const routes = () =>
  [
    <Route path="/usuarios/nuevo" element={<UsuarioEdit />} key="/usuarios/nuevo" />,
    <Route path="/usuarios/:id" element={<UsuarioEdit />} key="/usuarios/:id" />,
    <Route path="/usuarios" element={<UsuarioList />} key="/usuarios" />,
  ].map((route) => route);

export default routes;
