import React from 'react';
import {
  faAngleLeft,
  faAnglesLeft,
  faAngleRight,
  faAnglesRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface PaginationProps {
  pageSelected: number;
  totalPages: number;
  maxPagesToShow?: number;
  disabled?: boolean;
  onChange: (page: number) => void;
}

export default function Pagination({
  pageSelected,
  totalPages,
  maxPagesToShow: mpts = 5,
  disabled = false,
  onChange,
}: PaginationProps) {
  const onClickPageButton = (page: number) => {
    if (page != pageSelected) {
      onChange(page);
    }
  };
  // if value is even, substract 1. If value is less than 1, set it to 5
  const maxPagesToShow = mpts < 1 ? 5 : mpts % 2 === 0 ? mpts - 1 : mpts;

  const generatePageNumbers = () => {
    if (totalPages <= maxPagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (pageSelected <= Math.ceil(maxPagesToShow / 2)) {
      return Array.from({ length: maxPagesToShow }, (_, i) => i + 1);
    }

    if (pageSelected >= totalPages - Math.floor(maxPagesToShow / 2)) {
      return Array.from({ length: maxPagesToShow }, (_, i) => totalPages - maxPagesToShow + 1 + i);
    }

    return Array.from(
      { length: maxPagesToShow },
      (_, i) => pageSelected - Math.floor(maxPagesToShow / 2) + i,
    );
  };

  return (
    <nav>
      <ul className="pagination justify-content-center mt-3 mb-3">
        {pageSelected > 5 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              disabled={disabled}
              onClick={() => onClickPageButton(1)}>
              <FontAwesomeIcon icon={faAnglesLeft} fixedWidth />
            </button>
          </li>
        )}
        {pageSelected > 1 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              disabled={disabled}
              onClick={() => onClickPageButton(pageSelected - 1)}>
              <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
            </button>
          </li>
        )}
        {generatePageNumbers().map((page) => (
          <li key={page} className={`page-item ${page === pageSelected ? 'active' : ''}`}>
            <button
              type="button"
              className="page-link"
              disabled={disabled}
              onClick={() => onClickPageButton(page)}>
              {page}
            </button>
          </li>
        ))}
        {pageSelected < totalPages && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              disabled={disabled}
              onClick={() => onClickPageButton(pageSelected + 1)}>
              <FontAwesomeIcon icon={faAngleRight} fixedWidth />
            </button>
          </li>
        )}
        {pageSelected <= totalPages - 3 && (
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              disabled={disabled}
              onClick={() => onClickPageButton(totalPages)}>
              <FontAwesomeIcon icon={faAnglesRight} fixedWidth />
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
}
