import React from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { Props } from '../Interfaces/interfaces';
import { ProductCategory1ListState } from './productCategory1Interface';

class ProductCategory1List extends React.Component<Props, ProductCategory1ListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      productCategories1: [],
      isDataLoading: true,
    };

    this.loadProductCategories1 = this.loadProductCategories1.bind(this);
  }

  componentDidMount() {
    this.loadProductCategories1();
  }

  loadProductCategories1() {
    const { toastManager } = this.props;

    APIClient.get('/product-categories-1')
      .then((res) => {
        this.setState({
          productCategories1: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { productCategories1, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'code',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      {
        dataField: 'webDescription',
        text: 'Descripción web',
        sort: true,
      },
      // {
      //   dataField: 'actions',
      //   isDummyField: true,
      //   text: '',
      //   formatter: (cellContent:any, row:ProductCategory1) => (
      //     <ButtonToolbar>
      //       <ButtonGroup>
      //         <LinkContainer to={`/product-categories-1/${row.codigo}`}>
      //           <Button size="sm" variant="outline-primary" title="Editar">
      //             <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
      //           </Button>
      //         </LinkContainer>
      //       </ButtonGroup>
      //     </ButtonToolbar>
      //   ),
      // },
    ];

    const exportColumns = [
      {
        fieldName: 'code',
        title: 'Codigo',
      },
      {
        fieldName: 'description',
        title: 'Descripción',
      },
      {
        fieldName: 'webDescription',
        title: 'Descripción web',
      },
    ];

    return (
      <div>
        <h1 className="page-title">Categoría 1</h1>
        <DataTable
          columns={columns}
          data={productCategories1}
          isDataLoading={isDataLoading}
          keyField="codigo"
          //addButton="/product-categories-1/new"
          addButton={null}
          exportConfig={{
            exportURL: `/product-categories-1/export.xlsx`,
            columns: exportColumns,
          }}
        />
      </div>
    );
  }
}

export default withToastManager(ProductCategory1List);
