import React from 'react';
import { Route } from 'react-router-dom';
import PuntoEntregaList from './PuntoEntregaList';
import PuntoEntregaEdit from './PuntoEntregaEdit';

const routes = () =>
  [
    <Route
      path="/puntos-entrega/nuevo"
      element={<PuntoEntregaEdit />}
      key="/puntos-entrega/nuevo"
    />,
    <Route path="/puntos-entrega/:id" element={<PuntoEntregaEdit />} key="/puntos-entrega/:id" />,
    <Route path="/puntos-entrega" element={<PuntoEntregaList />} key="/puntos-entrega" />,
  ].map((route) => route);

export default routes;
