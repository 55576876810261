import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import DataTableExportCSVButton from './DataTableExportCSVButton';
import { formatAmount } from '../views/Utils';
import { DataTableFooterProps } from '../types/dataTable';


export default function DataTableFooter({
  paginationProps,
  propsTP,
  cols,
  getExportData: onGetExportData,
  exportExtraFields,
  exportCustomHeaders,
  exportFileName,
  exportConfig,
  total,
}: DataTableFooterProps) {
  return (
    <Card.Footer>
      {total && total > 0 ? (
        <Row className="mb-2">
          <Col className="text-right">Total: {formatAmount(total)}</Col>
        </Row>
      ) : null}
      <div className="d-flex justify-content-between">
        {paginationProps && (
          <>
            <SizePerPageDropdownStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </>
        )}

        { ( exportConfig && exportConfig.show !== false)  ? (
          <div className="text-right">
            <DataTableExportCSVButton
              disabled={false}
              onGetExportData={onGetExportData}
              exportConfig={exportConfig}
              // {...propsTP.csvProps}
              propsTP={propsTP}
              cols={cols}
              exportExtraFields={exportExtraFields}
              exportCustomHeaders={exportCustomHeaders}
              exportFileName={exportFileName}          
              />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Card.Footer>
  );
}
