import React, {FC} from 'react';
import { Form } from 'react-bootstrap';


interface Props {
  id:string;
  value?:string;
  label:string;
  required?:boolean;
  validText?:string;
  invalidText?:string;
  defaultValue?:string | undefined | number;
  disabled?:boolean;
  placeholder?:string;
  step?:string | number;
  min?:string | number | undefined;
  max?:string | number | undefined;
  type?:string;
  onChange?:(e: React.ChangeEvent<HTMLInputElement>)=>void;
 
}

const FormInput:FC<Props> = ({
  defaultValue,
  invalidText, 
  placeholder,
  validText, 
  disabled,
  required,
  value,
  label,
  step,
  type,
  min,
  max,
  id, 
  onChange,
}) => {

  return (
    <Form.Group controlId={id} className="mt-3">
      <Form.Label>{label}</Form.Label>
      <Form.Control  defaultValue={defaultValue} min={min} max={max} required={required} placeholder={placeholder} step={step} name={id} disabled={disabled} onChange={onChange} value={value} type={type}/>
      {validText && <Form.Control.Feedback type='valid'>{validText}</Form.Control.Feedback>}
      {invalidText && <Form.Control.Feedback type='invalid'>{invalidText}</Form.Control.Feedback>}
    </Form.Group>
  );
};

export default FormInput;
