import React from 'react';
import { Route } from 'react-router-dom';
import CompanyEdit from './CompanyEdit';

const routes = () =>
  [
    <Route path="/companies" element={<CompanyEdit />} key="/companies" />,
  ].map((route) => route);

export default routes;
