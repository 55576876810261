import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { LinkContainer } from 'react-router-bootstrap';
import './DataTable.css';
import DataTableSearchField from './DataTableSearchField';
import DataTableFooter from './DataTableFooter';
import Loading from './Loading';

class CustomBootstrapTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortField: '',
      sortOrder: '',
      page: props.pageNumber || 1,
      sizePerPage: 10,
    };
  }

  /**
   * receives table changes, generates parameters for query, saves changes to
   * state and calls onTableUpdate function.
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * @param  {string} searchText - current text in search bar
   * @param  {string} sortField - current field to sort by
   * @param  {string} sortOrder - current order to sort by
   */

  componentDidMount() {
    const {
      sortField, sortOrder, page, sizePerPage, searchText,
    } = this.state;

    this.handleTableChange('pagination', {
      sortField,
      sortOrder,
      page,
      sizePerPage,
      searchText,
    });
  }

  handleTableChange = async (type, {
    sortField, sortOrder, page, sizePerPage, searchText,
  }) => {
    const { sortField: stateSortField, sortOrder: stateSortOrder } = this.state;
    const { onTableUpdate } = this.props;
    const queryParameters = {};
    const sorting = {};
    queryParameters.pagination = {
      limit: sizePerPage,
      offset: page === 0 ? 0 : (page - 1) * sizePerPage,
      page,
    };
    if (searchText && searchText.length > 0) {
      queryParameters.freeText = searchText;
    } else {
      queryParameters.freeText = '';
    }
    if (sortField && sortField.length > 0 && sortOrder && sortOrder.length > 0) {
      sorting.field = sortField;
      sorting.direction = sortOrder.toUpperCase();
    }
    queryParameters.sorting = sorting;

    this.setState({
      page: type === 'search' ? 1 : page,
      sizePerPage,
      sortField: !sortField ? stateSortField : sortField,
      sortOrder: !sortOrder ? stateSortOrder : sortOrder,
    });
    await onTableUpdate(queryParameters);
  };

  render() {
    const {
      addButton,
      onSecondButtonClick,
      buttonText,
      buttonIcon,
      secondButtonText,
      secondButtonIcon,
      secondButtonVariant,
      defaultSorted,
      onTableRef,
      isDataLoading,
      showSearch,
      propsTP,
      paginationProps,
      paginationTableProps,
      renderMultiSelectionActions,
      selectRow,
      selectedRowIds,
      onSelect,
      onSelectAll,
      ...otherProps
    } = this.props;

    const selectRowConfig = renderMultiSelectionActions
      ? {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect,
        onSelectAll,
      }
      : {
        mode: 'checkbox',
        clickToSelect: true,
        hideSelectColumn: true,
      };

    const { page, sizePerPage } = this.state;
    if (paginationProps && otherProps.remote) {
      paginationProps.page = page;
      paginationProps.sizePerPage = sizePerPage;
    }
    const cellEdit = {
      mode: 'dbclick',
      afterSaveCell: (oldValue, newValue, row, column) => {
        this.props.updateRowField(row, column, newValue);
      },
    };

    return (
      <Card>
        <Card.Header>
          <Row>
            <Col md={12} className="d-flex justify-content-between">
              {addButton && typeof addButton === 'string' ? (
                <LinkContainer to={addButton} className="mb-2">
                  <Button variant="primary" size="sm">
                    <FontAwesomeIcon className="me-1" icon={buttonIcon || faPlus} fixedWidth />
                    {buttonText}
                  </Button>
                </LinkContainer>
              ) : (
                addButton && (
                  <Button variant="primary" onClick={addButton} size="sm">
                    <FontAwesomeIcon className="me-1" icon={buttonIcon || faPlus} fixedWidth />
                    {buttonText}
                  </Button>
                )
              )}

              {onSecondButtonClick && typeof onSecondButtonClick === 'string' ? (
                <LinkContainer to={onSecondButtonClick} className="ms-2">
                  <Button variant={secondButtonVariant || 'success'} size="sm">
                    <FontAwesomeIcon
                      className="me-1"
                      icon={secondButtonIcon || faPlus}
                      fixedWidth
                    />
                    {secondButtonText}
                  </Button>
                </LinkContainer>
              ) : (
                onSecondButtonClick && (
                  <Button
                    variant={secondButtonVariant || 'success'}
                    onClick={onSecondButtonClick}
                    size="sm"
                    className="ms-2"
                  >
                    <FontAwesomeIcon
                      className="me-1"
                      icon={secondButtonIcon || faPlus}
                      fixedWidth
                    />
                    {secondButtonText}
                  </Button>
                )
              )}
              {renderMultiSelectionActions}
            </Col>
            {!showSearch ? null : (
              <Col md={6}>
                <DataTableSearchField {...propsTP.searchProps} />
              </Col>
            )}
          </Row>
        </Card.Header>

        <BootstrapTable
          ref={(n) => onTableRef(n)}
          bootstrap4
          striped
          hover
          condensed
          responsive
          wrapperClasses="table-responsive"
          remote={otherProps.remote ? { ...otherProps.remote } : false}
          defaultSorted={defaultSorted}
          onTableChange={this.handleTableChange}
          selectRow={selectRowConfig}
          {...paginationTableProps}
          noDataIndication={() => {
            if (isDataLoading) {
              return <Loading />;
            }
            return <p>No hay información</p>;
          }}
          cellEdit={otherProps.updateRowField ? cellEditFactory(cellEdit) : cellEditFactory()}
          {...propsTP.baseProps}
          {...otherProps}
        />

        <DataTableFooter
          paginationProps={paginationProps}
          propsTP={propsTP}
          cols={propsTP.baseProps.columns}
          getExportData={otherProps.getExportData}
          exportConfig={otherProps.exportConfig}
          exportExtraFields={otherProps.exportExtraFields}
          exportCustomHeaders={otherProps.customHeaders}
          exportFileName={otherProps.exportFileName}
          {...otherProps}
        />
      </Card>
    );
  }
}

function DataTable(props) {
  const {
    addButton,
    onSecondButtonClick,
    buttonText,
    buttonIcon,
    secondButtonText,
    secondButtonIcon,
    secondButtonVariant,
    keyField,
    data: tableData,
    columns,
    defaultSorted,
    isDataLoading,
    showSearch,
    enablePagination,
    updateRowField,
    pageNumber,
    renderMultiSelectionActions,
    selectRow,
    exportConfig,
    ...otherProps
  } = props;

  let data = [];
  if (tableData && tableData.length > 0) {
    data = tableData;
  }

  const paginationOptions = {
    custom: true,
    totalSize: otherProps.totalSize ? otherProps.totalSize : data.length,
  };

  const curViewURLArr = window.location.href.split('/');
  let curViewName = `${curViewURLArr[curViewURLArr.length - 1]}`;
  if (curViewURLArr.length > 4) {
    curViewName = `${curViewURLArr[curViewURLArr.length - 2]}_${
      curViewURLArr[curViewURLArr.length - 1]
    }`;
  }

  const exportCSV = { fileName: `listado_${curViewName}_${moment().format('YYYYMMDDHHmmss')}.csv` };

  if (!enablePagination) {
    return (
      <ToolkitProvider
        bootstrap4
        keyField={keyField}
        data={data}
        columns={columns}
        exportCSV={exportCSV}
        search
      >
        {(propsTP) => (
          <CustomBootstrapTable
            addButton={addButton}
            onSecondButtonClick={onSecondButtonClick}
            buttonText={buttonText}
            buttonIcon={buttonIcon}
            secondButtonText={secondButtonText}
            secondButtonIcon={secondButtonIcon}
            secondButtonVariant={secondButtonVariant}
            defaultSorted={defaultSorted}
            isDataLoading={isDataLoading}
            propsTP={propsTP}
            showSearch={showSearch}
            exportConfig={exportConfig}
            updateRowField={updateRowField}
            pageNumber={pageNumber}
            renderMultiSelectionActions={renderMultiSelectionActions}
            selectRow={selectRow}
            {...otherProps}
          />
        )}
      </ToolkitProvider>
    );
  }
  return (
    <ToolkitProvider
      bootstrap4
      keyField={keyField}
      data={data}
      columns={columns}
      exportCSV={Object.keys(exportCSV).length > 0 ? exportCSV : null}
      search
    >
      {(propsTP) => (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <CustomBootstrapTable
              addButton={addButton}
              buttonText={buttonText}
              buttonIcon={buttonIcon}
              onSecondButtonClick={onSecondButtonClick}
              secondButtonText={secondButtonText}
              secondButtonIcon={secondButtonIcon}
              secondButtonVariant={secondButtonVariant}
              defaultSorted={defaultSorted}
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
              isDataLoading={isDataLoading}
              propsTP={propsTP}
              showSearch={showSearch}
              exportConfig={exportConfig}
              updateRowField={updateRowField}
              pageNumber={pageNumber}
              renderMultiSelectionActions={renderMultiSelectionActions}
              selectRow={selectRow}
              {...otherProps}
            />
          )}
        </PaginationProvider>
      )}
    </ToolkitProvider>
  );
}

DataTable.propTypes = {
  getExportData: PropTypes.func,
  rowClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  cols: PropTypes.arrayOf(PropTypes.object),
  cellEdit: PropTypes.object,
  selectRow: PropTypes.object,
  pageNumber: PropTypes.number,
  onTableUpdate: PropTypes.func,
  totalSize: PropTypes.number,
  remote: PropTypes.object,
  keyField: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  addButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.bool,
  ]),
  onSecondButtonClick: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.bool,
  ]),
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  secondButtonText: PropTypes.string,
  secondButtonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  secondButtonVariant: PropTypes.string,
  isDataLoading: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool,
  enablePagination: PropTypes.bool,
  exportFileName: PropTypes.string,
  exportExtraFields: PropTypes.arrayOf(PropTypes.string),
  customHeaders: PropTypes.arrayOf(PropTypes.string),
  updateRowField: PropTypes.func,
  renderMultiSelectionActions: PropTypes.element,
  selectedRowIds: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  exportConfig: PropTypes.shape({
    show: PropTypes.bool,
    exportURL: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string.isRequired,
        parseBoolean: PropTypes.bool,
        title: PropTypes.string.isRequired,
      }),
    ),
  }),
};
DataTable.defaultProps = {
  defaultSorted: [],
  addButton: null,
  onSecondButtonClick: null,
  buttonText: 'Agregar',
  cellEdit: { cellEdit: false },
  secondButtonText: '',
  showSearch: true,
  enablePagination: true,
  exportFileName: '',
  exportExtraFields: [],
  customHeaders: [''],
  updateRowField: () => {},
  secondButtonIcon: null,
  buttonIcon: null,
  secondButtonVariant: 'success',
  renderMultiSelectionActions: null,
  selectRow: null,
  selectedRowIds: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

CustomBootstrapTable.propTypes = {
  propsTP: PropTypes.object.isRequired,
  paginationProps: PropTypes.object,
  paginationTableProps: PropTypes.object,
  addButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.bool,
  ]),
  onSecondButtonClick: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.func,
    PropTypes.bool,
  ]),
  buttonText: PropTypes.string.isRequired,
  buttonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  secondButtonText: PropTypes.string,
  secondButtonIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  secondButtonVariant: PropTypes.string,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  isDataLoading: PropTypes.bool.isRequired,
  showSearch: PropTypes.bool,
  onTableRef: PropTypes.func,
  onTableUpdate: PropTypes.func,
  getExportData: PropTypes.func,
  renderMultiSelectionActions: PropTypes.element,
  selectRow: PropTypes.object,
  selectedRowIds: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
};

CustomBootstrapTable.defaultProps = {
  defaultSorted: [],
  addButton: null,
  onSecondButtonClick: null,
  showSearch: true,
  onTableRef: () => {},
  onTableUpdate: () => {},
  paginationTableProps: {},
  paginationProps: null,
  getExportData: () => {},
  secondButtonIcon: null,
  secondButtonText: null,
  buttonIcon: null,
  secondButtonVariant: 'success',
  renderMultiSelectionActions: null,
  selectRow: null,
  selectedRowIds: [],
  onSelect: () => {},
  onSelectAll: () => {},
};

export default DataTable;
