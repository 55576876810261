import axios from 'axios';
import config from '../config';
import Security from './Security';
import { APIResponse } from '../types/api';

const apiClient = {
  delete: async (path) => {
    try {
      const promise = await axios.delete(`${config.api.url}${path}`, {
        // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  get: async (path) => {
    try {
      const promise = await axios.get<APIResponse>(`${config.api.url}${path}`, {
        // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.response?.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  getFile: async (path, opts) => {
    try {
      const promise = await axios.get(`${path}`, {
        // withCredentials: true,
        ...opts,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  getImage: async (path, opts) => {
    try {
      const promise = await axios.get(`${path}`, {
        // withCredentials: true,
        ...opts,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  post: async (path, data) => {
    try {
      const promise = await axios.post<APIResponse>(`${config.api.url}${path}`, data, {
        // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  patch: async (path, data) => {
    try {
      const promise = await axios.patch<APIResponse>(`${config.api.url}${path}`, data, {
        // withCredentials: true,
        headers: Security.getAuthHeader(),
      });
      return promise;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.data.status === 401) {
        window.location.assign('/login');
        localStorage.clear();
      }
      throw e;
    }
  },
  // eslint-disable-next-line arrow-body-style
  downloadFile: async (url) => {
    return axios({
      method: 'GET',
      url,
      responseType: 'blob',
      headers: Security.getAuthHeader(),
    });
  },
};

export default apiClient;
