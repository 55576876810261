import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import { Card, Button, Modal, Form } from 'react-bootstrap';
import DataTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type as CellEditType } from 'react-bootstrap-table2-editor';
import { SelectedContact } from '../views/Cliente/clientInterface';
import { Contact } from '../types/model';
import { EMAIL_REGEX, PHONE_REGEX } from '../constants';
import UIUtils, { SettingsStatusBadge } from '../views/UIUtils';
import Utils from '../views/Utils';

export type ContactForm = {
  id?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  enabled?: boolean;
};

interface ContactListProps {
  contacts: Contact[];
  onSaveContact: (contact: ContactForm) => void;
  onDeleteContact: (contactId: number) => void;
}

export default function ContactList({
  contacts,
  onSaveContact,
  onDeleteContact,
}: ContactListProps) {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [newFirstName, setNewFirstName] = useState<string>('');
  const [newLastName, setNewLastName] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
  const [newEnabled, setNewEnabled] = useState<boolean>(true);
  const [selectedContact, setSelectedContact] = useState<SelectedContact | undefined>(undefined);

  const { addToast } = useToasts();

  const handleRowCellSaveClick = (oldValue: string, newValue: string, row: any) => {
    const updatedContact = contacts.find((contact) => contact.id === row.id);
    // check if field changed, if not do nothing
    if (!updatedContact || oldValue === newValue) {
      return;
    }
    onSaveContact(row);
  };

  const handleDeleteContactClick = () => {
    if (!selectedContact || !selectedContact.id) {
      return;
    }
    onDeleteContact(selectedContact.id);
    setShowDeleteModal(false);
  };

  const handleAddNewContactClick = () => {
    if (!newFirstName && !newLastName && !newEmail && !newPhoneNumber) {
      addToast('Por favor completa un campo.', {
        appearance: 'warning',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      return;
    }

    if (newEmail && !EMAIL_REGEX.test(newEmail)) {
      addToast('Por favor ingresa un e-mail válido.', {
        appearance: 'warning',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      return;
    }

    if (newPhoneNumber && !PHONE_REGEX.test(newPhoneNumber)) {
      addToast('Por favor ingresa un número válido.', {
        appearance: 'warning',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      return;
    }

    const newContact: ContactForm = {
      // clientId: Number(clientId),
      id: undefined,
      firstName: newFirstName,
      lastName: newLastName,
      email: newEmail,
      phoneNumber: newPhoneNumber,
      enabled: newEnabled,
    };

    setNewFirstName('');
    setNewLastName('');
    setNewEmail('');
    setNewPhoneNumber('');
    setNewEnabled(true);

    setShowAddModal(false);
    onSaveContact(newContact);
  };

  const handleCloseAddModalClick = () => {
    setShowAddModal(false);
  };

  const handleCloseDeleteModalClick = () => {
    setShowDeleteModal(false);
  };

  const handleRowAddButtonClick = () => {
    setShowAddModal(true);
  };

  const handleRowDeleteButtonClick = (row: any) => {
    const selectedContact = contacts.find((contact) => contact.id === row.id);
    setSelectedContact(selectedContact);
    setShowDeleteModal(true);
  };

  const contactColumns = [
    { dataField: 'firstName', text: 'Nombre' },
    { dataField: 'lastName', text: 'Apellido' },
    { dataField: 'email', text: 'E-mail' },
    { dataField: 'phoneNumber', text: 'Número de teléfono' },
    {
      dataField: 'enabled',
      text: 'Habilitado',
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        cellContent =
          typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
        const settings: SettingsStatusBadge = {
          text: { true: 'Activo', false: 'Inactivo' },
          color: { true: 'success', false: 'danger' },
        };
        return UIUtils.getStatusBadge(cellContent, settings);
      },
      editor: {
        type: CellEditType.CHECKBOX,
        value: 'true:false',
      },
    },
    {
      dataField: 'id',
      text: '',
      sort: false,
      align: 'center',
      headerAlign: 'center',
      formatter: (cell, row) => (
        <FontAwesomeIcon
          icon={faLinkSlash}
          onClick={() => handleRowDeleteButtonClick(row)}
          fixedWidth
          title="Desvincular contacto"
          size="xs"
          style={{ color: 'red', cursor: 'pointer' }}
        />
      ),
    },
  ];

  return (
    <>
      <Card.Body>
        <Card.Title>Contactos</Card.Title>
        {contacts.length > 0 ? (
          <DataTable
            cellEdit={cellEditFactory({
              mode: 'click',
              blurToSave: true,
              afterSaveCell: handleRowCellSaveClick,
            })}
            columns={contactColumns}
            data={contacts}
            keyField="id"
            selectRow={{
              align: 'center',
              clickToEdit: true,
              clickToSelect: true,
              hideSelectColumn: true,
              mode: 'checkbox',
            }}
            enablePagination={false}
          />
        ) : (
          <p>No hay contactos.</p>
        )}
        <Button onClick={handleRowAddButtonClick}>Agregar Contacto</Button>
      </Card.Body>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModalClick}>
        <Modal.Header closeButton>
          <Modal.Title>Desvincular contacto del Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de qué desea desvincular este contacto?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleDeleteContactClick}>
            Desvincular
          </Button>
          <Button variant="secondary" onClick={handleCloseDeleteModalClick}>
            Cancelar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModal} onHide={handleCloseAddModalClick}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Contacto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                value={newFirstName}
                onChange={(e) => setNewFirstName(e.target.value)}
              />
            </Form.Group>
            <div className="my-3"></div>

            <Form.Group>
              <Form.Label>Apellido</Form.Label>
              <Form.Control
                type="text"
                value={newLastName}
                onChange={(e) => setNewLastName(e.target.value)}
              />
            </Form.Group>
            <div className="my-3"></div>

            <Form.Group>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </Form.Group>
            <div className="my-3"></div>

            <Form.Group>
              <Form.Label>Número de teléfono</Form.Label>
              <Form.Control
                type="text"
                value={newPhoneNumber}
                onChange={(e) => setNewPhoneNumber(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModalClick}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleAddNewContactClick}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
