import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

export default function NotFound() {
  const location = useLocation();

  return (
    <div className="text-center py-4 lead">
      <p>
        <FontAwesomeIcon icon={faExclamationTriangle} size="7x" />
      </p>
      <p>{`La página "${location.pathname}" no se ha encontrado`}</p>
    </div>
  );
}
