import React from 'react';
import { Route } from 'react-router-dom';
import MarcaList from './MarcaList';
import MarcaEdit from './MarcaEdit';

const routes = () =>
  [
    <Route path="/marcas/nuevo" element={<MarcaEdit />} key="/marcas/nuevo" />,
    <Route path="/marcas/:id" element={<MarcaEdit />} key="/marcas/:id" />,
    <Route path="/marcas" element={<MarcaList />} key="/marcas/:id" />,
  ].map((route) => route);

export default routes;
