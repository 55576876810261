import React from 'react';
import { Route } from 'react-router-dom';
import ClienteList from './ClienteList';
import ClienteEdit from './ClienteEdit';

const routes = () =>
  [
    // <Route path="/clientes/nuevo" exact element={<ClienteEdit />} key="/clientes/nuevo" />,
    <Route path="/clientes/:id" element={<ClienteEdit />} key="/clientes/:id" />,
    <Route path="/clientes" element={<ClienteList />} key="/clientes" />,
  ].map((route) => route);

export default routes;
