import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { withToastManager } from 'react-toast-notifications';
import { Button, Card, Col, Row } from 'react-bootstrap';
import APIClient from '../../../services/APIClient';
import { DataTable, FormSelectField } from '../../../components';

class CustomerOrderReport extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      customerId: props.customerId,
      customers: [],
      details: [],
      isDataLoading: true,
      totalSize: 0,
      selectedCustomerId: '',
    };
  }

  async componentDidMount() {
    const { customerId } = this.state;

    await this.loadCustomers();
    if (customerId) {
      this.setState((prevState) => ({
        ...prevState,
        selectedCustomerId: customerId,
      }));
      this.onLoadData();
    }
  }

  // load all customers for customers select
  loadCustomers = async () => {
    const { toastManager } = this.props;
    try {
      const response = await APIClient.get('/clientes?&sortField=razonSocial&sortDir=asc');

      this.setState((prevState) => ({
        ...prevState,
        customers: response.data.data,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleFormChange = (e) => {
    const { value, name } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  onLoadData = async () => {
    const { toastManager } = this.props;
    const { selectedCustomerId } = this.state;
    try {
      this.setState((prevState) => ({
        ...prevState,
        isDataLoading: true,
      }));

      const customerOrdersRes = await APIClient.get(
        `/customer-report/${selectedCustomerId}/orders`,
      );
      this.setState((prevState) => ({
        ...prevState,
        details: customerOrdersRes.data.data,
        totalSize: customerOrdersRes.data.data.length,
        isDataLoading: false,
      }));
    } catch (error) {
      toastManager.add(`Ocurrió un error: ${error}`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  render() {
    const { customers, details, selectedCustomerId, isDataLoading, totalSize } = this.state;

    const columns = [
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
      },
      {
        dataField: 'estado',
        text: 'Estado',
        sort: true,
      },
      {
        dataField: 'fechaAlta',
        text: 'Fecha de alta',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'fechaEntrega',
        text: 'Fecha de entrega',
        sort: true,
        formatter: (cellContent) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'lista_precio',
        text: 'Lista de precio',
        sort: true,
      },
      {
        dataField: 'forma_de_pago',
        text: 'Forma de pago',
        sort: true,
      },
      {
        dataField: 'precio_entrega',
        text: 'Precio de entrega',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title className="mb-4">Historial de pedidos</Card.Title>
            <Row className="d-flex justify-content-between">
              <Col md={6}>
                <FormSelectField
                  id="selectedCustomerId"
                  label="Selecciona un cliente"
                  value={selectedCustomerId}
                  onChange={this.handleFormChange}
                  choices={customers}
                  choiceIdField="id"
                  choiceLabelField="razonSocial"
                />
              </Col>
              <Col md={1}>
                <Button variant="primary" size="md" className="mt-4" onClick={this.onLoadData}>
                  Buscar
                </Button>
              </Col>
            </Row>
            <DataTable
              columns={columns}
              data={details || []}
              keyField="codigo"
              isDataLoading={isDataLoading}
              totalSize={totalSize}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(CustomerOrderReport);
