import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  faEdit,
  faCircleNotch,
  faCaretRight,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { Type } from 'react-bootstrap-table2-editor';
import { WithRouterProps, withRouter } from '../withRouter';
import { withToastManager } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';

import { DataTable, FiltersPanel } from '../../components';
import FormSelect from '../../components/componentsTs/FormSelect';

import { ProductListState } from './productInterface';
import { QueryParameters } from '../Cliente/clientInterface';
import { Marca, ProductCategory1, ProductCategory2, Proveedor } from '../Interfaces/interfaces';

import Utils from '../Utils';
import UIUtils from '../UIUtils';
import APIClient from '../../services/APIClient';

class ProductList extends React.Component<WithRouterProps, ProductListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: WithRouterProps) {
    super(props);
    const query = Utils.sanitizeQuery(['page'], this.props.location.search).page;
    const getPageNumber = parseInt(query);
    const pageNumber = Utils.isPositiveInteger(getPageNumber) ? getPageNumber : 1;
    this.state = {
      apiParams: '',
      products: [],
      isDataLoading: true,
      totalSize: 0,
      //FILTROS Y ROW EDIT
      marcas: [],
      proveedores: [],
      productCategories1: [],
      productCategories2: [],
      filterStringMarca: '',
      filterStringProveedor: '',
      filterStringProductCategory1: '',
      filterStringProductCategory2: '',
      selectedMarcaIds: [],
      selectedProveedorIds: [],
      selectedProductCategory1Codes: [],
      selectedProductCategory2Ids: [],
      isFiltersCollapsed: true,
      pageNumber,
    };

    this.loadProducts = this.loadProducts.bind(this);
  }

  componentDidMount() {
    this.getFilterAndEditData();
    this.loadProducts();
  }

  onTableUpdate = async (queryParameters: QueryParameters) => {
    this.setState({ isDataLoading: true });
    const { toastManager } = this.props;
    const { freeText, pagination, sorting } = queryParameters;
    const {
      filterStringMarca,
      filterStringProductCategory1,
      filterStringProductCategory2,
      filterStringProveedor,
      pageNumber,
    } = this.state;

    try {
      let { direction, field } = sorting;
      // prepare the URL query for filters
      const filterQuery = [
        filterStringMarca,
        filterStringProductCategory1,
        filterStringProductCategory2,
        filterStringProveedor,
      ]
        .filter((filterString) => !!filterString)
        .join('&');

      if (field && field === 'productCategory1') {
        field = field.concat('_code');
      }
      if (field && field === 'productCategory2') {
        field = field.concat('_id');
      }
      if (field && field === 'marca') {
        field = field.concat('Codigo');
      }
      const apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${
        field || 'id'
      }&sortDir=${direction || 'desc'}&excludeAssocFields=imagenes`;
      const productsRes = await APIClient.get(
        `/products?limit=${pagination.limit}&offset=${pagination.offset}&${apiParams}&${filterQuery}`,
      );
      const products = productsRes.data.data;
      window.history.pushState({ page: pagination.page ?? 1 }, '', `?page=${pagination.page ?? 1}`);
      this.setState({
        apiParams,
        products,
        totalSize: productsRes.data.meta.total,
        pageNumber: pagination.page ?? 1,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isDataLoading: false });
    }
  };

  async loadProducts() {
    const { toastManager } = this.props;
    const { pageNumber } = this.state;
    const offset = (pageNumber - 1) * 10;
    try {
      // get paginated list of products sorts by entity id
      const productsRes = await APIClient.get(
        `/products?limit=10&sortField=id&sortDir=desc&offset=${offset}&excludeAssocFields=imagenes`,
      );

      this.setState({
        products: productsRes.data.data,
        isDataLoading: false,
        totalSize: productsRes.data.meta.total,
      });
    } catch (error: any) {
      toastManager.add(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  //#region FILTROS --------------------------------------------

  getFilterAndEditData = async () => {
    const { toastManager } = this.props;
    try {
      // get marcas
      const marcasRes = await APIClient.get('/marcas');
      // get productCategories1
      const productCategories1Res = await APIClient.get('/product-categories-1');
      // get productCategories2
      const productCategories2Res = await APIClient.get('/product-categories-2');
      // get proveedores
      const proveedoresRes = await APIClient.get('/proveedores');

      this.setState({
        marcas: marcasRes.data.data,
        productCategories1: productCategories1Res.data.data,
        productCategories2: productCategories2Res.data.data,
        proveedores: proveedoresRes.data.data,
      });
    } catch (err: any) {
      console.error('Error al obtener la información de los filtros: ', err);
      toastManager.add(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  };

  createSelectAllButtons = (entityName: string, label?: string) => {
    let labelToRender = label;
    if (!labelToRender) {
      labelToRender = `${entityName.substr(0, 1).toUpperCase()}${entityName.substr(1)}`;
    }
    return (
      <p className="m-0">
        {labelToRender} (
        <button
          id={`select-all-${entityName}`}
          type="submit"
          className="link-button text-primary"
          onClick={(e) => this.handleSelectAll(e, entityName)}>
          Seleccionar todos
        </button>
        )
      </p>
    );
  };

  handleDataChange = (e: any) => {
    const { id, options } = e.target;

    let filterName = '';
    let fieldName = '';
    let filterQuery: string | undefined;

    switch (id) {
      case 'selectedMarcaIds':
        fieldName = 'marcaCodigo';
        filterName = 'filterStringMarca';
        break;

      case 'selectedProductCategory1Codes':
        fieldName = 'category1Code';
        filterName = 'filterStringProductCategory1';
        break;

      case 'selectedProductCategory2Ids':
        fieldName = 'category2Id';
        filterName = 'filterStringProductCategory2';
        break;

      case 'selectedProveedorIds':
        fieldName = 'proveedorId';
        filterName = 'filterStringProveedor';
        break;

      default:
        break;
    }

    let whereOp = [...options].filter((opt) => opt.selected).length > 1 ? 'or' : 'eq';
    let values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);

    // if all options are selected clears the filter
    if (values.length == options.length) {
      filterQuery = Utils.prepareQueryToFilter(fieldName, '', []);
    } else {
      filterQuery = Utils.prepareQueryToFilter(fieldName, whereOp, values);
    }

    this.setState((prevState) => ({
      ...prevState,
      [id]: values,
      [filterName]: Utils.prepareQueryToFilter(fieldName, whereOp, values),
    }));
  };

  handleSelectAll = (e: any, entityName: any) => {
    const { marcas, productCategories1, productCategories2, proveedores } = this.state;

    let valueIds: string[] = [];
    let selectedName = '';
    let filterName = '';
    let fieldName = '';

    // map ids from entities into "selected" variables
    switch (entityName) {
      case 'marcas':
        valueIds = marcas.map((brand: Marca) => brand.codigo);
        selectedName = 'selectedMarcaIds';
        filterName = 'filterStringMarca';
        fieldName = 'marcaCodigo';
        break;

      case 'productCategories1':
        valueIds = productCategories1.map((category: ProductCategory1) => category.code);
        selectedName = 'selectedProductCategory1Codes';
        filterName = 'filterStringProductCategory1';
        fieldName = 'producId';
        break;

      case 'productCategories2':
        valueIds = productCategories2.map((category: ProductCategory2) => String(category.id));
        selectedName = 'selectedProductCategory2Ids';
        filterName = 'filterStringProductCategory2';
        fieldName = 'producId';
        break;

      case 'proveedores':
        valueIds = proveedores.map((supplier: Proveedor) => String(supplier.id));
        selectedName = 'selectedProveedorIds';
        filterName = 'filterStringProveedor';
        fieldName = 'proveedorId';
        break;

      default:
        return;
    }

    this.setState((prevState) => ({
      ...prevState,
      [selectedName]: valueIds,
      [filterName]: Utils.prepareQueryToFilter(fieldName, '', []),
    }));
  };

  handleFiltersCollapseClick = (e: any) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isFiltersCollapsed: !prevState.isFiltersCollapsed,
    }));
  };

  handleFiltersCleanClick = () => {
    this.setState({
      selectedMarcaIds: [],
      selectedProductCategory1Codes: [],
      selectedProductCategory2Ids: [],
      selectedProveedorIds: [],
      filterStringMarca: '',
      filterStringProductCategory1: '',
      filterStringProductCategory2: '',
      filterStringProveedor: '',
    });
  };

  //#region FIN_FILTROS --------------------------------------------

  //#region EDIT CELLS --------------------------------------------

  updateRowField = async (row: any, column: any) => {
    const { toastManager } = this.props;
    const { products } = this.state;
    try {
      //call to api UPDATE
      await APIClient.patch(`/products/${row.id}`, row);
      toastManager.add(`Cambio guardado`, {
        appearance: 'success',
      });
      // Update the component's state with the changes
      const productToUpdateState = await APIClient.get(`/products/${row.id}`);
      const arrayToUpdateState = products.map((art) => {
        if (art.id === row.id) {
          return productToUpdateState.data.data;
        }
        return art;
      });
      this.setState((prevState) => ({
        ...prevState,
        products: arrayToUpdateState,
      }));
    } catch (err: any) {
      console.error('Error al actualizar el campo: ', err);
      toastManager.add(`No se pudo guardar la información. Inténtelo nuevamente. ${err}`, {
        appearance: 'error',
      });
    }
  };

  //#endregion FIN EDIT_CELLS --------------------------------------------

  render() {
    const {
      marcas,
      products,
      totalSize,
      apiParams,
      pageNumber,
      proveedores,
      isDataLoading,
      selectedMarcaIds,
      productCategories1,
      productCategories2,
      isFiltersCollapsed,
      selectedProveedorIds,
      selectedProductCategory1Codes,
      selectedProductCategory2Ids,
    } = this.state;

    const events = {
      onMouseEnter: (e: any) => {
        e.target.style.cursor = `pointer`;
      },
    };

    const columns = [
      {
        dataField: 'erpCodigo',
        text: 'Código ERP',
        sort: true,
        events: events,
      },
      {
        dataField: 'codigoEan13',
        text: 'EAN',
        sort: true,
        events: events,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
        events: events,
      },
      {
        dataField: 'marcaCodigo',
        text: 'Marca',
        sort: true,
        events: events,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          const marcaFound = marcas.find((marca) => cellContent === marca.codigo);
          const marca = marcaFound ? marcaFound.descripcion : row.marca.descripcion;
          return marca;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            return marcas.map((marca) => {
              return { value: marca.codigo, label: marca.descripcion };
            });
          },
        },
      },
      {
        dataField: 'category1Code',
        text: 'Categoría 1',
        sort: true,
        events: events,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          const productCategory1Found = productCategories1.find((lin) => cellContent === lin.code);
          const productCategory1 = productCategory1Found
            ? productCategory1Found.description
            : row.productCategory1.descripcion;
          return productCategory1;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            return productCategories1.map((lin) => {
              return { value: lin.code, label: lin.description };
            });
          },
        },
      },
      {
        dataField: 'proveedorId',
        text: 'Proveedor',
        csvExport: false,
        events: events,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
          const proveedorFound = proveedores.find((proveedor) => cellContent === proveedor.id);
          const proveedor = proveedorFound ? proveedorFound.nombre : row.proveedor.nombre;
          return proveedor;
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            return proveedores.map((proveedor) => {
              return { value: proveedor.id, label: proveedor.nombre };
            });
          },
        },
      },
      {
        dataField: 'isEliminado',
        text: 'Estado',
        //si el flag es false el producto está activo
        csvExport: false,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent =
            typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
          const UISettings = {
            text: { true: 'Inactivo', false: 'Activo' },
            color: { true: 'danger', false: 'success' },
          };
          return UIUtils.getStatusBadge(cellContent, UISettings);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            const arrayForOptionsEstado = [
              { value: false, label: 'Activo' },
              { value: true, label: 'Inactivo' },
            ];
            return arrayForOptionsEstado.map((estado) => {
              return { value: estado.value, label: estado.label };
            });
          },
        },
      },
      {
        dataField: 'isAgotado',
        text: 'Stock',
        //si el flag es false el producto está en stock
        csvExport: false,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent !== 'number' ? parseInt(cellContent, 10) : cellContent;
          const UISettings = {
            text: { true: 'Agotado', false: 'En stock' },
            color: { true: 'danger', false: 'success' },
          };
          return UIUtils.getStatusBadge(cellContent, UISettings);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            const arrayForOptionsEstado = [
              { value: 0, label: 'En stock' },
              { value: 1, label: 'Agotado' },
            ];
            return arrayForOptionsEstado.map((estado) => {
              return { value: estado.value, label: estado.label };
            });
          },
        },
      },
      {
        dataField: 'isPromo',
        text: 'Promoción',
        //si el flag es false el producto está en NO está en promoción
        csvExport: false,
        events: UIUtils.bgBlueOnMouseEnter,
        formatter: (cellContent: any, row: any) => {
          if (cellContent === null) {
            return '';
          }
          cellContent = typeof cellContent !== 'number' ? parseInt(cellContent, 10) : cellContent;
          const UISettings = {
            text: { true: 'Sí', false: 'No' },
            color: { true: 'success', false: 'danger' },
          };
          return UIUtils.getStatusBadge(cellContent, UISettings);
        },
        editor: {
          type: Type.SELECT,
          getOptions: (row: any, column: any) => {
            const arrayForOptionsEstado = [
              { value: 0, label: 'No' },
              { value: 1, label: 'Sí' },
            ];
            return arrayForOptionsEstado.map((estado) => {
              return { value: estado.value, label: estado.label };
            });
          },
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent: any, row: any) => (
          <ButtonToolbar>
            <ButtonGroup>
              <Link to={{ pathname: `/products/${row.id}`, search: `?page=${pageNumber}` }}>
                <Button size="sm" variant="outline-primary" title="Editar">
                  <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                </Button>
              </Link>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Productos</h1>

        <FiltersPanel
          isDataLoading={isDataLoading}
          onFilterClick={() =>
            this.onTableUpdate({
              freeText: '',
              pagination: { limit: 10, offset: 0 },
              sorting: {},
            })
          }
          onClearClick={this.handleFiltersCleanClick}>
          <Row>
            <Col md={6}>
              <FormSelect
                id="selectedMarcaIds"
                label={this.createSelectAllButtons('marcas')}
                value={selectedMarcaIds}
                onChange={this.handleDataChange}
                choices={marcas}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                multiple
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="selectedProductCategory1Codes"
                label={this.createSelectAllButtons('productCategories1', 'Categorías 1')}
                value={selectedProductCategory1Codes}
                onChange={this.handleDataChange}
                choices={productCategories1}
                choiceIdField="code"
                choiceLabelField="description"
                multiple
              />
            </Col>
            <Col md={6}>
              <FormSelect
                id="selectedProductCategory2Ids"
                label={this.createSelectAllButtons('productCategories2', 'Categorías 2')}
                value={selectedProductCategory2Ids}
                onChange={this.handleDataChange}
                choices={productCategories2}
                choiceIdField="id"
                choiceLabelField="description"
                multiple
              />
            </Col>
            <Col md={6} className="my-3">
              <FormSelect
                id="selectedProveedorIds"
                label={this.createSelectAllButtons('proveedores')}
                value={selectedProveedorIds}
                onChange={this.handleDataChange}
                choices={proveedores}
                choiceIdField="id"
                choiceLabelField="nombre"
                multiple
              />
            </Col>
          </Row>
        </FiltersPanel>

        <DataTable
          remote={{
            filter: true,
            pagination: true,
            sort: true,
          }}
          totalSize={totalSize}
          columns={columns}
          data={products}
          onTableUpdate={this.onTableUpdate}
          isDataLoading={isDataLoading}
          keyField="id"
          exportConfig={{
            exportURL:`/products/export.xlsx?${apiParams}`
          }}
          updateRowField={this.updateRowField}
          pageNumber={pageNumber}
        />
      </div>
    );
  }
}

export default withToastManager(withRouter(ProductList));
