import { faCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { withToastManager } from 'react-toast-notifications';
import { FormInputField } from '../components';
import APIClient from '../services/APIClient';
import { WithRouterProps } from './withRouter';

interface PasswordChangeProps extends WithRouterProps {}

function PasswordChange({ toastManager }: PasswordChangeProps) {
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isMatch, setIsMatch] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');

  function onFormChange(e) {
    const { id, value } = e.target;

    // update field value
    switch (id) {
      case 'newPassword':
        setNewPassword(value);
        break;
      case 'oldPassword':
        setOldPassword(value);
        break;
      case 'confirmedPassword':
        setConfirmedPassword(value);
        break;
      default:
        // do nothing
        break;
    }

    if (id.match(/(new|confirmed)Password/)) {
      setIsMatch(validatePasswords(value));
    }
    if (id === 'newPassword') {
      setIsValid(value.length >= 6);
    }
  }

  async function onFormSubmit(e) {
    e.preventDefault(e);
    e.stopPropagation();

    const body = { newPassword, oldPassword };
    setIsDataLoading(true);
    try {
      await APIClient.post('/me/cambiar-contrasena', body);
      toastManager.add('Se cambió la contraseña correctamente.', { appearance: 'success' });

      setConfirmedPassword('');
      setIsMatch(false);
      setIsValid(false);
      setNewPassword('');
      setOldPassword('');
    } catch (err: Error | any) {
      if (err.response?.status === 400) {
        toastManager.add(`Ocurrió un error: ${err.response.data.message}`, {
          appearance: 'warning',
        });
      } else {
        toastManager.add(`Ocurrió un error al intentar actualizar la contraseña: ${err.message}`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } finally {
      setIsDataLoading(false);
    }
  }

  function validatePasswords(confirmedPassword) {
    return newPassword === confirmedPassword;
  }

  return (
    <div>
      <h1 className="page-title">Cambiar Contraseña</h1>

      <Form onSubmit={onFormSubmit} onChange={onFormChange}>
        <Row>
          <Col md={4}>
            <FormInputField
              id="oldPassword"
              label="Contraseña Actual"
              type="password"
              value={oldPassword}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormInputField
              id="newPassword"
              label="Nueva Contraseña"
              type="password"
              value={newPassword}
              isInvalid={!isValid && newPassword.length > 0}
              invalidText="La contraseña debe tener un mínimo de 6 caracteres."
              disabled={isDataLoading}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <FormInputField
              id="confirmedPassword"
              label="Confirmar Contraseña"
              type="password"
              value={confirmedPassword}
              isInvalid={!isMatch && confirmedPassword.length > 0}
              invalidText="Las contraseñas no coinciden."
              disabled={isDataLoading}
              required
            />
          </Col>
        </Row>
        <Button type="submit" variant="primary" disabled={isDataLoading || !(isMatch && isValid)}>
          {isDataLoading ? (
            <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
          ) : (
            <FontAwesomeIcon icon={faCheck} fixedWidth className="me-1" />
          )}
          Cambiar
        </Button>
      </Form>
    </div>
  );
}

export default withToastManager(PasswordChange);
