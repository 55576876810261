import React from 'react';
import { Route } from 'react-router-dom';
import SyncDashboard from './SyncDashboard';

const routes = () =>
  [<Route path="/sincronizacion" element={<SyncDashboard />} key="/sincronizacion" />].map(
    (route) => route,
  );

export default routes;
