import React, { useState } from 'react';
import { Card, Container, Form, Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import APIClient from '../../services/APIClient';
import securityService from '../../services/Security';
import Logo from '../../assets/images/ordertob_logotipo_251x40.png'
import './Login.css';

export default function Login() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  function handleInputChange(event) {
    const { value, name } = event.target;

    switch (name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
      default:
        console.error(`Unexpected input key: "${name}"`);
        break;
    }
  }

  function onSubmit(event) {
    event.preventDefault();

    const data = {
      username,
      password,
    };

    APIClient.post('/authenticate', data)
      .then((res) => {
        securityService.setSession(res.data.data);
        navigate('/');
      })
      .catch((err) => {
        console.error('Authenticating with API', err);
        setErrorMessage('Usuario o contraseña incorrectos.');
      });
  }

  return (
    <Container className="login-page">
      <Container className="my-4 login-logo">
        <Image src={Logo} fluid />
      </Container>
      <h1 className="text-center">Central de Pedidos</h1>
      <Card>
        <Card.Body>
          <p>Ingrese su usuario o e-mail, y contraseña.</p>
          {errorMessage !== '' && <p className="text-danger">{errorMessage}</p>}
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                name="username"
                placeholder="Usuario o e-mail"
                value={username}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="password"
                name="password"
                placeholder="Contraseña"
                value={password}
                onChange={handleInputChange}
                required
              />
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="primary" type="submit">
                Ingresar
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
