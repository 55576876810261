
import React from 'react';
import {
  Popover, OverlayTrigger, Button, Row, Col, Table
} from 'react-bootstrap';

class FacturaPopOver extends React.Component {
  constructor(props) {
    const { data, text, makeRowsFromDetalles } = props;
    super(props);
    this.state = {
      data,
      text,
      makeRowsFromDetalles,
    };
  }

  popover = () => {
    const { data, text, makeRowsFromDetalles } = this.state;
    const products = data.transaccionProductoItems;
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{text}</Popover.Title>
        <Popover.Content>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Cant.</th>
                <th>Precio</th>
                <th>Importe</th>
              </tr>
            </thead>
            <tbody>
              {makeRowsFromDetalles(products)}
            </tbody>
          </Table>
          <Row className="bg-success m-2 p-2 ">
            <Col md={3}>Total</Col>
            <Col md={{ offset: 6, span: 3 }}>
              {products.length ? products.reduce((counter, art) => {
                return (counter + art.cantidad * parseFloat(art.precioconivaincluido));
              }, 0).toFixed(2) : null}
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );

  }

  render() {
    const { text } = this.state;
    return (
      <Col md={2}>
        <OverlayTrigger trigger="click" placement="bottom" overlay={this.popover}>
          <Button variant="success">{text}</Button>
        </OverlayTrigger>
      </Col>
    );
  }
}

export default FacturaPopOver;
