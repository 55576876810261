import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { ClienteTipo, Props } from '../Interfaces/interfaces';
import { ClienteTipoState } from './clienteTipoInterface';

class ClienteTipoList extends React.Component<Props, ClienteTipoState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      clienteTipos: [],
      isDataLoading: true,
    };

    this.loadClienteTipos = this.loadClienteTipos.bind(this);
  }

  componentDidMount() {
    this.loadClienteTipos();
  }

  loadClienteTipos() {
    const { toastManager } = this.props;

    APIClient.get('/cliente-tipos')
      .then((res) => {
        this.setState({
          clienteTipos: res.data.data,
          isDataLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { clienteTipos, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'descripcion',
        text: 'Descripción',
        sort: true,
      },
      // {
      //   dataField: 'actions',
      //   isDummyField: true,
      //   text: '',
      //   formatter: (cellContent:any, row:ClienteTipo) => (
      //     <ButtonToolbar>
      //       <ButtonGroup>
      //         <LinkContainer to={`/cliente-tipos/${row.id}`}>
      //           <Button size="sm" variant="outline-primary" title="Editar">
      //             <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
      //           </Button>
      //         </LinkContainer>
      //       </ButtonGroup>
      //     </ButtonToolbar>
      //   ),
      // },
    ];

    /* EXPORT DATA TO EXCEL */
    const exportColums = [
      {
        fieldName: 'id',
        title: 'ID',
      },
      {
        fieldName: 'descripcion',
        title: 'Descripción',
      },
    ];

    return (
      <div>
        <h1 className="page-title">Tipos de Cliente</h1>

        <DataTable
          columns={columns}
          data={clienteTipos}
          isDataLoading={isDataLoading}
          keyField="id"
          exportConfig={{
            exportURL: '/cliente-tipos/export.xlsx',
            columns: exportColums,
          }}
        />
      </div>
    );
  }
}

export default withToastManager(ClienteTipoList);
