import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import APIClient from '../services/APIClient';

export default function ProtectedRoutes() {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isRedirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    console.debug('Checking token...');
    APIClient.get('/check-token')
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
        } else {
          const error = new Error(res.statusText);
          throw error;
        }
      })
      .catch((err) => {
        console.warn('Not logged in', err);
        setLoading(false);
        setRedirect(true);
      });
  });

  if (isLoading) {
    return null;
  }
  if (isRedirect) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}
