import React from 'react';
import { Route } from 'react-router-dom';
import ZonaList from './ZonaList';
// import ZonaEdit from './ZonaEdit';

const routes = () =>
  [
    // <Route path="/zonas/nueva" exact element={<ZonaEdit />} key="/zonas/nueva" />,
    // <Route path="/zonas/:id(\d+)" element={<ZonaEdit />} key="/zonas/:id" />,
    <Route path="/zonas" element={<ZonaList />} key="/zonas" />,
  ].map((route) => route);

export default routes;
