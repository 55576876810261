import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Card } from 'react-bootstrap';
import { DataTable } from '../../../components';
import APIClient from '../../../services/APIClient';

class CustomersStatesReport extends Component {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      clientes: [],
      total: [],
      isDataLoading: true,
      totalSize: 0,
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  loadData = async () => {
    const { toastManager } = this.props;
    try {
      const response = await APIClient.get('/customer-report/states');
      this.setState(prevState => ({
        ...prevState,
        clientes: response.data.data,
        isDataLoading: false,
        totalSize: response.data.data.length,
      }));
    } catch (error) {
      toastManager.add('Ocurrió un error', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  render() {
    const { clientes, totalSize, isDataLoading } = this.state;

    const columns = [
      {
        dataField: 'rubro',
        text: 'Rubros',
        sort: true,
      },
      {
        dataField: 'estado',
        text: 'Estado',
        sort: true,
      },
      {
        dataField: 'cantidad',
        text: 'Cantidad de clientes',
        sort: true,
        align: 'right',
        headerAlign: 'right',
      },
    ];

    return (
      <div>
        <Card>
          <Card.Body>
            <Card.Title>Reporte de clientes y prospectos</Card.Title>
            <DataTable
              columns={columns}
              data={clientes}
              totalSize={totalSize}
              isDataLoading={isDataLoading}
              keyField="id"
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default withToastManager(CustomersStatesReport);
