import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

/** @deprecated Use `React Router hooks` instead */
export interface WithRouterProps {
  toastManager: any;
  location: ReturnType<typeof useLocation>;
  params: any;
  navigate: ReturnType<typeof useNavigate>;
}

/** @deprecated Use `React Router hooks` instead */
export function withRouter<ComponentProps extends WithRouterProps>(
  Component: React.ComponentType<ComponentProps>,
) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...(props as ComponentProps)}
        location={location}
        navigate={navigate}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}
