import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Moment from 'react-moment';

import Login from './views/Security/Login';
import ProtectedRoutes from './views/ProtectedRoutes';
import Remito from './views/Remito';

import './App.css';
import Layout from './views/Layout';

// Moment defaults
Moment.globalFormat = 'YYYY-MM-DD HH:mm';

function App() {
  return (
    <BrowserRouter>
      <ToastProvider autoDismissTimeout="6000" placement="bottom-right">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="/remitos/:id" element={<Remito />} />
            <Route path="/*" element={<Layout />} />
          </Route>
        </Routes>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;
