import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, Modal, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faSyncAlt,
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import Moment from 'react-moment';
import UIUtils from '../UIUtils';

interface ItemToSync {
  id: number;
  name: string;
  lastSync: Date;
  lastSuccessfulSync: Date;
  service: string;
  successfulSyncRecords: number;
  failedSyncRecords: number;
  status: string;
}

function SyncDashboard() {
  const { addToast } = useToasts();

  const [itemsToSync, setItemsToSync] = React.useState([
    {
      id: 1,
      name: 'Productos',
      lastSync: new Date('2023-09-26T15:30:00Z'),
      lastSuccessfulSync: new Date('2023-09-26T15:30:00Z'),
      service: 'SAP',
      successfulSyncRecords: 12,
      failedSyncRecords: 2,
      status: 'success',
    },
    {
      id: 2,
      name: 'Pedidos',
      lastSync: new Date('2023-09-26T15:30:00Z'),
      lastSuccessfulSync: new Date('2023-09-26T15:30:00Z'),
      service: 'SAP',
      successfulSyncRecords: 14,
      failedSyncRecords: 2,
      status: 'error',
    },
    {
      id: 3,
      name: 'Clientes',
      lastSync: new Date('2023-09-26T15:30:00Z'),
      lastSuccessfulSync: new Date('2023-09-26T15:30:00Z'),
      service: 'SAP',
      successfulSyncRecords: 14,
      failedSyncRecords: 2,
      status: 'error',
    },
    {
      id: 4,
      name: 'Listas de precios',
      lastSync: new Date('2023-09-26T15:30:00Z'),
      lastSuccessfulSync: new Date('2023-09-26T15:30:00Z'),
      service: 'SAP',
      successfulSyncRecords: 13,
      failedSyncRecords: 2,
      status: 'in_process',
    },
  ] as ItemToSync[]);

  const [isModalLogVisible, setModalLogVisible] = React.useState(false); // State variable to manage modal visibility

  const openModalLog = () => {
    setModalLogVisible(true); // Set showModal to true to show the modal
  };

  const closeModalLog = () => {
    setModalLogVisible(false); // Set showModal to false to hide the modal
  };

  const handleSyncClick = (item: ItemToSync) => {
    let itemToUpdate = itemsToSync.findIndex((obj) => obj.id == item.id);

    if (itemToUpdate !== -1) {
      let itemsToSyncCopy = [...itemsToSync];
      itemsToSyncCopy[itemToUpdate].status = 'success';
      setItemsToSync(itemsToSyncCopy);
      addToast('Sincronizado correctamente', { appearance: 'success' });
    }
  };

  const log = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dapibus metus eget purus tristique faucibus. Mauris et bibendum nulla. Sed euismod nulla id risus sagittis tristique. Duis id urna congue, efficitur leo et, congue enim. Vestibulum ullamcorper orci enim. Nulla gravida erat in fringilla dapibus. Integer volutpat, arcu et viverra lacinia, enim lacus tempus nunc, nec luctus mauris nisi quis diam. Sed at sapien a tellus tempor condimentum. Quisque quis est leo. Phasellus mattis nibh in ex molestie maximus. Ut nec odio bibendum, lacinia nunc nec, dignissim nunc.

  Suspendisse eget turpis at orci suscipit efficitur malesuada quis nisl. Phasellus malesuada mauris eu nisi sodales, sed lacinia nisi ornare. Aenean faucibus mauris id tortor condimentum, eu mattis justo dictum. Aenean interdum gravida pharetra. Donec congue nunc nec convallis venenatis. Nunc tempus eleifend odio id lacinia. Aenean finibus tempor est non ullamcorper. Sed sit amet ligula at sem egestas gravida. Praesent magna dolor, pharetra at ex dapibus, pretium scelerisque arcu. Sed orci orci, faucibus at sagittis ut, posuere eget dolor.
  
  Pellentesque feugiat ex non mollis blandit. Donec suscipit tempor dui sed efficitur. Sed pellentesque ante ac turpis tempor aliquam. Suspendisse potenti. Sed finibus velit ante, sit amet commodo urna rhoncus ac. Pellentesque aliquet eros sed nunc euismod, et malesuada dolor facilisis. In euismod, mi vitae eleifend ultricies, nisl urna tincidunt turpis, vitae condimentum purus enim quis nisl. In et risus fermentum ligula ornare consequat a ac nulla. In ac nibh libero. Fusce vestibulum fermentum mattis. Quisque id ex dignissim, vestibulum orci ac, viverra nisl. Curabitur a urna ac est finibus venenatis at vel lectus.
  
  Vivamus eget sapien ut ipsum ultrices vehicula. Curabitur a rutrum libero. Integer a sapien sed velit rhoncus luctus non congue eros. Donec diam purus, gravida eget dolor ac, commodo egestas mi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris quis auctor orci, in eleifend est. Morbi et nisl cursus, faucibus mauris eu, laoreet augue. Quisque sagittis at ex ut sollicitudin. Maecenas in sem ac dolor congue auctor at eu magna. Phasellus tristique metus ac aliquet suscipit. In ligula ligula, rhoncus in viverra sit amet, pretium sed quam. Proin euismod nisi vitae turpis ultricies molestie. Integer massa est, blandit sed diam eget, ultrices dignissim magna. Curabitur quis quam sed arcu viverra bibendum. Nunc ac finibus purus. Nunc molestie enim non sapien luctus, in fermentum ligula auctor.
  
  Etiam ultricies odio in quam luctus, eu volutpat tellus aliquet. Aenean et dolor nisi. Nunc enim velit, interdum vitae malesuada efficitur, malesuada sit amet sem. Sed vestibulum ullamcorper ante, at ornare sapien dignissim mattis. Vivamus fermentum urna sed euismod rhoncus. Morbi massa sapien, sollicitudin vel viverra in, accumsan dignissim velit. Phasellus varius condimentum diam et convallis. Curabitur ut urna imperdiet, rutrum velit at, mollis tortor. Quisque sit amet lobortis erat, eleifend tincidunt nunc. Nam quis odio nec quam viverra mollis. Integer id faucibus enim. Aliquam facilisis porta nibh id tempus. Vivamus dapibus mauris nec porta fringilla. Sed mattis orci enim, ut sollicitudin risus tempor id. Sed leo nibh, volutpat in vulputate vitae, dictum non risus.`;

  return (
    <div>
      {
        <Modal size="lg" show={isModalLogVisible} scrollable={true} onHide={() => closeModalLog()}>
          <Modal.Header closeButton>
            <Modal.Title>Log</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{log}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => closeModalLog()}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      }
      <h1 className="page-title">Sincronización</h1>
      {itemsToSync.map((item) => {
        return (
          <div className="border rounded-lg col-lg-12 mb-3 p-3" key={item.id}>
            <div className="d-flex justify-content-between align-items-start position-relative">
              <div className="d-flex align-items-center">
                <h3 style={{ marginRight: '8px' }}>{item.name}</h3>
                {UIUtils.getSyncStateBadge(item.status)}
              </div>
              <div className="position-absolute" style={{ right: '0px' }}>
                <ButtonToolbar className="d-flex justify-content-end align-items-center">
                  <ButtonGroup>
                    <Button
                      size="sm"
                      variant="outline-primary"
                      onClick={() => openModalLog()}
                      title="Ver log">
                      <FontAwesomeIcon icon={faFileAlt} fixedWidth size="xs" />
                    </Button>
                    <Button
                      size="sm"
                      disabled={false}
                      variant="outline-primary"
                      onClick={() => handleSyncClick(item)}
                      title="Anular">
                      <FontAwesomeIcon icon={faSyncAlt} fixedWidth size="xs" />
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
                <p>
                  <span className="font-italic">{item.service}</span>
                </p>
              </div>
            </div>
            <Row>
              <Col md={6}>
                <p className="font-weight-normal">
                  Última sincronización:{' '}
                  <span className="font-italic">
                    <Moment>{item.lastSync}</Moment>
                  </span>
                </p>
                <p className="font-weight-normal">
                  Última sincronización exitosa:{' '}
                  <span className="font-italic">
                    <Moment>{item.lastSuccessfulSync}</Moment>
                  </span>
                </p>
              </Col>
              <Col md={6}>
                <p className="font-weight-normal">Registros: </p>
                <p className="d-flex align-items-center">
                  <FontAwesomeIcon
                    className="text-success"
                    icon={faCheckCircle}
                    fixedWidth
                    size="xs"
                  />
                  <span className="font-italic">{item.successfulSyncRecords}</span>&nbsp;-&nbsp;
                  <FontAwesomeIcon
                    className="text-warning"
                    icon={faExclamationTriangle}
                    fixedWidth
                    size="xs"
                  />
                  <span className="font-italic">{item.failedSyncRecords}</span>
                </p>
              </Col>
            </Row>
            <div>
              <form className="d-flex">
                <div className="d-flex align-items-center" style={{ marginRight: '40px' }}>
                  <input type="checkbox" style={{ marginRight: '7px', alignItems: 'center' }} />
                  <label style={{ marginBottom: '0' }}>Sincronización automática</label>
                </div>
                <div className="d-flex align-items-center">
                  <label className="me-2 mb-0">Cada: </label>
                  <input
                    className="me-2 form-control"
                    style={{ fontSize: '0.8rem' }}
                    type="number"
                    min="1"
                    step="1"
                  />
                  <select className="me-2 form-control" style={{ fontSize: '0.8rem' }}>
                    <option value="hour">Horas</option>
                    <option value="day">Días</option>
                    <option value="week">Semanas</option>
                    <option value="month">Meses</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default SyncDashboard;
