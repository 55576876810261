export enum SyncState {
  InProcess = 'in_process',
  Success = 'success',
  Error = 'error',
  PartialSuccess = 'partial_success',
}

export enum StatusCode {
  nonDelivered = 'NE',
  draft = 'SA',
  cancelled = 'SB',
  delivered = 'SC',
  confirmed = 'SE',
  inPreparation = 'SF',
  invoiced = 'IV',
  deliveredPartial = 'SP',
  pending = 'PC',
}

export enum OrderMisc {
  prefijo = 'MR',
  marca = 1,
  detalleTalleNombre = 'ST',
}

export enum InputMode {
  batch = 'colectivo',
  individual = 'individual',
}

export enum CodeRelation {
  pendiente = 'LIMPEND',
}

export enum SourceChannel {
  integration = 'integration',
  backOffice = 'back_office',
  portalWeb = 'portal_web',
  whatsapp = 'whatsapp',
  email = 'email',
  api = 'api',
}

export enum SenderType {
  user = 'user',
  ordertob = 'ordertob',
}

export enum ConversationMessageContentType {
  audio = 'audio',
  document = 'document',
  text = 'text',
}

export enum DownloadFileType {
  audio = 'audio',
  csv = 'csv',
  document = 'document',
  spreadsheet = 'spreadsheet',
  image = 'image',
  pdf = 'pdf',
  text = 'text',
}

// ordertob-core
export enum ConversationStatus {
  inProgress = 'in_progress',
  finishedOrder = 'finished_order',
  finishedManual = 'finished_manual',
  finishedClient = 'finished_client',
  finishedErrorWhatsapp = 'finished_error_whatsapp',
  expired = 'expired'
}

/* eslint-disable no-useless-escape */
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PHONE_REGEX =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
/* eslint-enable no-useless-escape */
