import React from 'react';
import { Route } from 'react-router-dom';
import ProductList from './ProductList';
import ProductEdit from './ProductEdit';

const routes = () =>
  [
    // <Route path="/products/nuevo" exact element={<ProductEdit />} key="/products/nuevo" />,
    <Route path="/products/:id" element={<ProductEdit />} key="/products/:id" />,
    <Route path="/products" element={<ProductList />} key="/products" />,
  ].map((route) => route);

export default routes;
