/**
 * This function retrieves the authentication header for the current user by fetching the authentication token from local storage.
 * If the authentication token is not null, it sets the Authorization header with the token in the format 'Bearer authToken'.
 * @returns {Object} The authentication headers object containing the Authorization token.
 */
function getAuthHeader(): { Authorization?: string } {
  const authHeader: { Authorization?: string } = {};
  const authToken = localStorage.getItem('auth_token');
  if (authToken !== null) {
    authHeader.Authorization = `Bearer ${authToken}`;
  }

  return authHeader;
}

function setSession(authData) {
  localStorage.setItem('user_id', authData.id);
  localStorage.setItem('user_email', authData.email);
  localStorage.setItem('user_username', authData.username);
  localStorage.setItem('auth_token', authData.token);
}

function clearSession() {
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_email');
  localStorage.removeItem('user_username');
  localStorage.removeItem('auth_token');
}

function getSession() {
  const sessionData = {
    authToken: localStorage.getItem('auth_token'),
    user: {
      id: localStorage.getItem('user_id'),
      email: localStorage.getItem('user_email'),
      username: localStorage.getItem('user_username'),
    },
  };

  return sessionData;
}

export default {
  getAuthHeader,
  setSession,
  clearSession,
  getSession,
};
