import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import CustomersStatesReport from './CustomersStateReport.js';
import CustomerPriceReport from './CustomerPriceReport';
import CustomerOrderReport from './CustomerOrderReport';
import CustomerPurchasedPerMonth from './CustomerPurchasedPerMonth.js';
import Utils from '../../Utils';
import CustomerOrdervsSales from './CustomerOrdervsSales.js';

class CustomersReport extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { reporte } = Utils.sanitizeQuery(['reporte'], props.location.search);
    const { id } = props.match.params;
    this.state = {
      customerId: id,
      reportKey: reporte,
    };
  }

  render() {
    const { customerId, reportKey } = this.state;
    return (
      <div>
        <Row className="d-flex align-items-center page-title">
          <Col md={6}>
            <h1>Reportes de clientes</h1>
          </Col>
        </Row>
        <Tabs defaultActiveKey="estado" activeKey={reportKey} id="uncontrolled-tab-example">
          <Tab eventKey="estado" title="Estado">
            <CustomersStatesReport />
          </Tab>
          <Tab eventKey="precios" title="Precios y descuentos">
            <CustomerPriceReport customerId={customerId} />
          </Tab>
          <Tab eventKey="pedidos" title="Pedidos">
            <CustomerOrderReport customerId={customerId} />
          </Tab>
          <Tab eventKey="productos" title="Productos por mes">
            <CustomerPurchasedPerMonth />
          </Tab>
          <Tab eventKey="orderVsSales" title="Comparativa entre pedidos y entregados">
            <CustomerOrdervsSales />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default withToastManager(CustomersReport);
