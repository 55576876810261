import React from 'react';
import { Badge } from 'react-bootstrap';
import { SyncState, StatusCode } from '../constants';

export interface SettingsStatusBadge {
  color: {
    true: string;
    false: string;
  };
  text: {
    true: string;
    false: string;
  };
}

/**
 * Devuelve el nombre de un estado dado el codigo
 * @param {string} codigo Codigo de estado
 */
function getSolicitudEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case StatusCode.nonDelivered:
      nombre = 'No Entregado';
      variant = 'secondary';
      break;
    case StatusCode.draft:
      nombre = 'Borrador';
      variant = 'dark';
      break;
    case StatusCode.pending:
      nombre = 'Pendiente';
      variant = 'warning';
      break;
    case StatusCode.cancelled:
      nombre = 'Cancelado';
      variant = 'danger';
      break;
    case StatusCode.delivered:
      nombre = 'Entregado';
      variant = 'secondary';
      break;
    case StatusCode.confirmed:
      nombre = 'Confirmado';
      variant = 'success';
      break;
    case StatusCode.deliveredPartial:
      nombre = 'Entregado Parcial';
      variant = 'info';
      break;
    case StatusCode.inPreparation:
      nombre = 'En Preparación';
      variant = 'primary';
      break;
    case StatusCode.invoiced:
      nombre = 'Facturado';
      variant = 'primary';
      break;
    default:
      break;
  }
  return <Badge bg={variant}>{nombre}</Badge>;
}

function getEntregaEstadoBadge(code: string): JSX.Element {
  let nombre = '-';
  let variant = 'secondary';

  switch (code) {
    case 'EA':
      nombre = 'En Carga';
      variant = 'light';
      break;
    case 'EC':
      nombre = 'Confirmada';
      variant = 'success';
      break;
    case 'EF':
      nombre = 'Facturada';
      variant = 'info';
      break;
    case 'PC':
      nombre = 'Pendiente';
      variant = 'warning';
      break;
    default:
      break;
  }

  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getSyncStateBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case SyncState.InProcess:
      nombre = 'En proceso';
      variant = 'info';
      break;
    case SyncState.Success:
      nombre = 'Sincronizado';
      variant = 'success';
      break;
    case SyncState.Error:
      nombre = 'Error';
      variant = 'danger';
      break;
    case SyncState.PartialSuccess:
      nombre = 'Sincronizado con errores';
      variant = 'warning';
      break;
    default:
      break;
  }
  return <span className={`badge badge-${variant}`}>{nombre}</span>;
}

function getStatusBadge(fieldStatus: boolean, settingsObject: SettingsStatusBadge): JSX.Element {
  const statusKey = fieldStatus ? 'true' : 'false';

  return (
    <span className={`badge bg-${settingsObject.color[statusKey]}`}>
      {settingsObject.text[statusKey]}
    </span>
  );
}

function getClienteEstadoBadge(codigo) {
  let nombre = '-';
  let variant = 'secondary';
  switch (codigo) {
    case 'Activo':
      nombre = codigo;
      variant = 'success';
      break;
    case 'Inactivo':
      nombre = codigo;
      variant = 'danger';
      break;
    case 'Prospecto':
      nombre = codigo;
      variant = 'warning';
      break;
    default:
      break;
  }
  return <span className={`badge bg-${variant}`}>{nombre}</span>;
}

function generateHours() {
  const newHours: { hour: string }[] = [];
  for (let i = 0; i < 24; i += 1) {
    let hour = i.toString();
    if (i < 10) {
      hour = hour.padStart(2, '0');
    }
    newHours.push({ hour: `${hour}:00` });
    newHours.push({ hour: `${hour}:30` });
  }
  return newHours;
}

function generateRandomColors(n) {
  const randomColors: string[] = [];
  for (let i = 0; i < n; i += 1) {
    let randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    if (randomColor.length < 7) {
      const newColor = generateRandomColors(1)[0];
      randomColor = newColor;
    }
    randomColors.push(randomColor);
  }
  return randomColors;
}
const bgBlueOnMouseEnter = {
  onMouseEnter: (e) => {
    e.target.style.cursor = `pointer`;
    // if (e.target.tagName === 'TD') {
    //   e.target.classList.add('bg-primary');
    //   e.target.style.color = 'white';
    // }
    // if (e.target.tagName === 'DIV') {
    //   e.target.parentNode.classList.add('bg-primary');
    //   e.target.parentNode.style.color = 'white';
    // }
    // if (e.target.tagName === 'SMALL') {
    //   e.target.parentNode.parentNode.classList.add('bg-primary');
    //   e.target.parentNode.parentNode.style.color = 'white';
    // }
    // if (e.target.tagName === 'A') {
    //   e.target.parentNode.parentNode.classList.add('bg-primary');
    // }
  },
  onMouseLeave: (e) => {
    // if (e.target.tagName === 'TD') {
    //   e.target.classList.remove('bg-primary');
    //   e.target.style.color = 'black';
    // }
    // if (e.target.tagName === 'DIV') {
    //   e.target.parentNode.classList.remove('bg-primary');
    //   e.target.parentNode.style.color = 'black';
    // }
    // if (e.target.tagName === 'SMALL') {
    //   e.target.parentNode.parentNode.classList.remove('bg-primary');
    //   e.target.parentNode.parentNode.style.color = 'black';
    // }
    // if (e.target.tagName === 'A') {
    //   e.target.parentNode.parentNode.classList.remove('bg-primary');
    //   e.target.style.color = '#007BFF';
    // }
  },
};

function getBase64FromBlob(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      let base64String = fileReader.result as string;
      base64String = base64String.substring(base64String.indexOf(',') + 1);
      resolve(base64String);
    };
    fileReader.onerror = (ev) => {
      console.error('Error reading Blob', ev);
      reject(new Error('Error reading Blob'));
    };

    fileReader.readAsDataURL(blob);
  });
}

export default {
  getSolicitudEstadoBadge,
  generateHours,
  generateRandomColors,
  getEntregaEstadoBadge,
  getSyncStateBadge,
  getClienteEstadoBadge,
  bgBlueOnMouseEnter,
  getStatusBadge,
  getBase64FromBlob,
};
