import React, { PropsWithChildren, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

interface FiltersPanelProps {
  isDataLoading: boolean;
  title?: string;
  onFilterClick: React.MouseEventHandler<HTMLButtonElement>;
  onClearClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default function FiltersPanel({
  children,
  title = 'Filtros de búsqueda',
  isDataLoading,
  onFilterClick,
  onClearClick,
}: PropsWithChildren<FiltersPanelProps>) {
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState<boolean>(true);

  function handleFiltersCollapseClick() {
    setIsFiltersCollapsed(!isFiltersCollapsed);
  }

  return (
    <Card className="my-3">
      <Card.Header className="d-flex justify-content-between">
        <Button
          onClick={handleFiltersCollapseClick}
          className="mt-2 mb-2 bg-transparent btn btn-link">
          {`${title} `}
          <FontAwesomeIcon
            icon={isFiltersCollapsed ? faCaretRight : faCaretDown}
            fixedWidth
            className="ml-1"
          />
        </Button>
      </Card.Header>
      {!isFiltersCollapsed && (
        <>
          <Card.Body>{children}</Card.Body>
          <Card.Footer>
            <div className="d-flex flex-row justify-content-end">
              <Button
                disabled={isDataLoading}
                className="d-flex py-2 m-1 my-3"
                variant="primary"
                onClick={onFilterClick}>
                {!isDataLoading ? (
                  <>Buscar</>
                ) : (
                  <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="m-1" />
                )}
              </Button>
              <Button
                variant="secondary"
                className="d-flex py-2 mx-1 my-3"
                onClick={onClearClick}
                disabled={isDataLoading}>
                <>Limpiar Filtros</>
              </Button>
            </div>
          </Card.Footer>
        </>
      )}
    </Card>
  );
}
