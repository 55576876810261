/**
 * Checks whether an object is empty (`{}`) or not
 * @param object Object to verify
 * @returns 
 */
export function isObjectEmpty(object: any) {
  return Object.keys(object).length === 0 && object.constructor === Object;
}

/**
 * Compares two objects for equality.
 *
 * @param {any} object1 - The first object to compare
 * @param {any} object2 - The second object to compare
 * @return {boolean} true if the objects have the same key-value pairs, false otherwise
 */
export const isObjectEqual = (object1: any, object2:any) => {
  if (object1 == undefined || object2 == undefined) {
    return false;
  }
  const entriesA = Object.entries(object1);
  if (entriesA.length !== Object.entries(object2).length) {
    return false;
  }

  // Check if all entries have the same values in both objects
  return entriesA.every(([key, value]) => object2[key] === value);
};


/**
 * Checks if two arrays of objects are equal.
 *
 * @param {any} array1 - the first array to compare
 * @param {any} array2 - the second array to compare
 * @return {boolean} true if the arrays are equal, false otherwise
 */
export const isArrayEqual = (array1: any, array2: any) => {
  const isEqual =
    array1.length === array2.length &&
    array1.every((obj, index) => Object.keys(obj).every((key) => obj[key] === array2[index][key]));
  return isEqual;
};

