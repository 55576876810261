import React from 'react';
import { Route } from 'react-router-dom';
import ProductCategory3Edit from './productCategory3Edit';
import ProductCategory3List from './productCategory3List';

const routes = () =>
  [
    <Route
      path="/product-categories-3/new"
      element={<ProductCategory3Edit />}
      key="/product-categories-3/new"
    />,
    <Route
      path="/product-categories-3/:id"
      element={<ProductCategory3Edit />}
      key="/product-categories-3/:id"
    />,
    <Route
      path="/product-categories-3"
      element={<ProductCategory3List />}
      key="/product-categories-3"
    />,
  ].map((route) => route);

export default routes;
