import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Badge, Col, Row, Table } from 'react-bootstrap';
import { AIExecution, Order } from '../../types/model';
import apiClient from '../../services/APIClient';
import Moment from 'react-moment';
import { SourceChannel } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';

enum AIExecutionStatus {
  processing = 'processing',
  success = 'success',
  error = 'error',
}

enum OrderItemMatchByFields {
  none = 'none',
  code = 'code',
  eanCode = 'ean-code',
  description = 'description',
}

enum OrderClientMatchByFields {
  missing = 'missing',
  none = 'none',
  email = 'email',
  phone = 'phone',
  name = 'name',
}

enum OrderDeliveryPointMatchByFields {
  missing = 'missing',
  none = 'none',
  name = 'name',
  address = 'address',
}
interface InterpretOrderResult {
  client: {
    id?: number;
    hint?: string;
    matchBy: OrderClientMatchByFields;
    matchPrecision: number;
  };
  deliveryDate?: Date;
  deliveryPoint: {
    id?: number;
    hint?: string;
    matchBy: OrderDeliveryPointMatchByFields;
    matchPrecision: number;
  };
  items: {
    productId?: number;
    product?: string;
    quantity: number;
    unit: string;
    matchBy: OrderItemMatchByFields;
    matchPrecision: number;
  }[];
  notes?: string;
}

interface OrderAIExecutionModalProps {
  show: boolean;
  order: Order;
  onHide: Function;
}

export default function OrderAIExecutionModal({ show, order, onHide }: OrderAIExecutionModalProps) {
  const [isShown, setShown] = useState<boolean>(show);
  const [aiExecution, setAiExecution] = useState<AIExecution>();
  const [aiExecutionResultOrder, setAiExecutionResultOrder] = useState<InterpretOrderResult>();

  useEffect(() => {
    setShown(show);
  }, [show]);

  useEffect(() => {
    // Only load if not loaded before for this order. And if there's an execution for this Order
    if ((!aiExecution || aiExecution.id != order.aiExecutionId) && order.aiExecutionId) {
      fetchAiExecution().catch((error) => {
        console.error('Error fetching the AI Execution', error);
      });
    } else {
      console.debug('AIExecution already loaded, or not existent for Order.');
    }
  }, [order]);

  async function fetchAiExecution() {
    const aiExecutionResponse = await apiClient.get(`/ai/executions/${order.aiExecutionId}`);
    const aiExecution: AIExecution = aiExecutionResponse.data.data;
    setAiExecution(aiExecution);
    if (aiExecution.resultOrder) {
      setAiExecutionResultOrder(JSON.parse(aiExecution.resultOrder));
    } else {
      setAiExecutionResultOrder(undefined);
    }
  }

  function renderPrecision(precision?: number) {
    if (!precision) {
      return '';
    }
    return ` (${(precision ?? 0) * 100}%)`;
  }

  function renderAIExecutionStatusBadge() {
    let bg = 'secondary';
    switch (aiExecution?.status) {
      case AIExecutionStatus.success:
        bg = 'success';
        break;
      case AIExecutionStatus.processing:
        bg = 'warning';
        break;
      case AIExecutionStatus.error:
        bg = 'danger';
        break;
      default:
        // none
        break;
    }

    return <Badge bg={bg}>{aiExecution?.status}</Badge>;
  }

  function renderSourceChannel(aiExecutionMedium: string) {
    let sourceChannel;
    switch (aiExecutionMedium) {
      case SourceChannel.backOffice:
        sourceChannel = 'Central de Pedidos';
        break;
      case SourceChannel.portalWeb:
        sourceChannel = 'Portal Web';
        break;
      case SourceChannel.whatsapp:
        sourceChannel = 'WhatsApp';
        break;
      case SourceChannel.api:
        sourceChannel = 'API';
        break;
      case SourceChannel.integration:
        sourceChannel = 'Integración';
        break;
      case SourceChannel.email:
        sourceChannel = 'E-mail';
        break;
      default:
        sourceChannel = '';
        break;
    }
    return (
      <span>
        {sourceChannel}
        <FontAwesomeIcon icon={faRobot} title="Interpretado por AI" className="ms-1" size="xs" />
      </span>
    );
  }

  function renderMatchByBadge(
    elementMatcheable?: InterpretOrderResult['client'] | InterpretOrderResult['deliveryPoint'],
  ) {
    if (!elementMatcheable) return null;

    let matchByLabel = '';
    let matchByColor = '';
    switch (elementMatcheable.matchBy) {
      case 'none':
        matchByLabel = 'no identificado/encontrado';
        matchByColor = 'danger';
        break;
      case 'missing':
        matchByLabel = 'no indicado';
        matchByColor = 'warning';
        break;
      default:
        matchByLabel = elementMatcheable.matchBy;
        matchByColor = 'success';
        break;
    }

    return (
      <Badge bg={matchByColor}>
        {matchByLabel}
        {renderPrecision(elementMatcheable.matchPrecision)}
      </Badge>
    );
  }

  function handleHide() {
    setShown(false);
    if (onHide) onHide();
  }

  function handleCloseClick() {
    setShown(false);
    if (onHide) onHide();
  }

  return (
    <Modal size="lg" show={isShown} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Información de interpretación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {aiExecution ? (
          <>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Estado</Form.Label>
                  <div>{renderAIExecutionStatusBadge()}</div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Inicio</Form.Label>
                  <div>
                    <Moment interval={0} className="" utc>
                      {aiExecution.startedAt}
                    </Moment>
                  </div>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Canal</Form.Label>
                  <div>{renderSourceChannel(order.sourceChannel)}</div>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Texto original</Form.Label>
              <Form.Control
                as="textarea"
                plaintext
                readOnly
                defaultValue={aiExecution.request}
                className="border p-2"
              />
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Cliente
              </Form.Label>
              <Col sm={9}>
                {aiExecutionResultOrder?.client.hint}{' '}
                {renderMatchByBadge(aiExecutionResultOrder?.client)}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Fecha de Entrega
              </Form.Label>
              <Col sm={9}>
                {aiExecutionResultOrder?.deliveryDate ? (
                  <>
                    <Moment interval={0} className="me-2" format="DD/MM/YYYY" utc>
                      {aiExecutionResultOrder?.deliveryDate}
                    </Moment>
                    <Badge bg="success">encontrado</Badge>
                  </>
                ) : (
                  <Badge bg="warning">no indicado</Badge>
                )}
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>
                Punto de Entrega
              </Form.Label>
              <Col sm={9}>
                {aiExecutionResultOrder?.deliveryPoint.hint}{' '}
                {renderMatchByBadge(aiExecutionResultOrder?.deliveryPoint)}
              </Col>
            </Form.Group>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Producto interpretado</th>
                  <th className="text-end">Cantidad</th>
                  <th>Unidad</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {aiExecutionResultOrder?.items.map((item) => (
                  <tr>
                    <td>{item.product}</td>
                    <td>{item.productId && `ID = ${item.productId}`}</td>
                    <td className="text-end">{item.quantity}</td>
                    <td>{item.unit}</td>
                    <td>
                      <Badge
                        bg={item.matchBy == OrderItemMatchByFields.none ? 'danger' : 'success'}>
                        {item.matchBy}
                        {renderPrecision(item.matchPrecision)}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <p>Esta orden no fue interpretada por Inteligencia Artificial</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseClick}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
