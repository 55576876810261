import React from 'react';
import { Route } from 'react-router-dom';

import ConversationList from './ConversationList';

const routes = () =>
  [
    <Route path="/conversations/:id?" element={<ConversationList />} key="/conversations/:id?"  />,
  ].map((route) => route);

export default routes;
