import React from 'react';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';

function FormSelectField(props) {
  const {
    id,
    label,
    choices,
    value,
    defaultValue,
    required,
    placeholder,
    choiceIdField,
    choiceLabelField,
    arrayvalidateindex,
    aiStatusIconColor,
    aiInterpretDescription,
    ...otherProps
  } = props;

  const aiTooltip = (
    <Tooltip>{aiInterpretDescription}</Tooltip>
  );

  return (
    <Form.Group className="mt-3" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <div className="d-flex align-items-center justify-content-between">
        <Form.Select
          name={id}
          multiple={otherProps.multiple}
          as="select"
          value={value}
          className={
            arrayvalidateindex
            && arrayvalidateindex.indexOf(id) >= 0 ? 'border border-danger' : ''
          }
          defaultValue={defaultValue}
          {...otherProps}>
          {!required && !otherProps.multiple && <option value="">{placeholder}</option>}
          {choices.map((choice) => (
            <option value={choice[choiceIdField]} key={choice[choiceIdField]}>
              {typeof choiceLabelField === 'string'
                ? choice[choiceLabelField]
                : choiceLabelField(choice)}
            </option>
          ))}
        </Form.Select>
        {aiInterpretDescription && (
          <OverlayTrigger overlay={aiTooltip}>
            <div className="ms-2">
              <FontAwesomeIcon icon={faMagic} color={aiStatusIconColor} fixedWidth />
            </div>
          </OverlayTrigger>
        )}
      </div>
      <Form.Control.Feedback type="invalid">Please provide a valid zip.</Form.Control.Feedback>
    </Form.Group>
  );
}

FormSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
  defaultValue: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  choiceIdField: PropTypes.string,
  choiceLabelField: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  arrayvalidateindex: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  value: PropTypes.string,
  aiStatusIconColor: PropTypes.string,
  aiInterpretDescription: PropTypes.string,
};
FormSelectField.defaultProps = {
  required: false,
  placeholder: '(Seleccione)',
  defaultValue: undefined,
  choiceIdField: 'id',
  choiceLabelField: 'label',
  arrayvalidateindex: [],
  disabled: false,
  onChange: () => {},
  aiStatusIconColor: undefined,
  aiInterpretDescription: undefined,
};

export default FormSelectField;
