import React from 'react';
import { Route } from 'react-router-dom';
import VendedorList from './VendedorList';
// import VendedorEdit from './VendedorEdit';

const routes = () =>
  [
    // <Route path="/vendedores/nuevo" exact element={<VendedorEdit />} key="/vendedor/nuevo" />,
    // <Route path="/vendedores/:id" element={<VendedorEdit />} key="/vendedor/:id" />,
    <Route path="/vendedores" element={<VendedorList />} key="/vendedor" />,
  ].map((route) => route);

export default routes;
